import React, { FC, useEffect, useState } from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

import useStyles from '../styles/useStyles'
import { ShippingNotificationOption } from '../constants/Constants'
import { ShippingValues } from '../types/ShippingValuesTypes'
import { TestId } from 'constants/testIds'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ICountryCodes } from 'interfaces/IPasscode'
import { getCountryCodes } from 'services/PasscodeService'
import TextFieldWithCharacterCount from './TextFieldWithCharacterCount'

export const ShippingNotificationComponent: FC = () => {
    const classes = useStyles()
    const [countryCodes, setCountryCodes] = useState<string[]>()

    const {
        control,
        formState: { errors },
    } = useFormContext<ShippingValues>()

    useEffect(() => {
        getCountryCodes().then((result: ICountryCodes) => {
            const codes = result && result.countryCodes.map((item) => item.countryAbbr)
            setCountryCodes(codes)
        })
    }, [])

    return (
        <>
            <Grid item xs={12}>
                <div className={classes.receiveBadgeTitle}>
                    <h4>How would you like to be notified about the status of your request?</h4>
                </div>
            </Grid>
            <Grid item xs={12} data-testid={TestId.ShippingNotificationGrid}>
                <div style={{ textAlign: 'center' }}>
                    <Controller
                        render={({ field }) => (
                            <RadioGroup {...field}>
                                <FormControlLabel value={JSON.stringify(ShippingNotificationOption.Email)} control={<Radio color="primary" />} label="Email" />
                                {field.value === JSON.stringify(ShippingNotificationOption.Email) && (
                                    <Grid container className={classes.textInput}>
                                        <Grid item xs={12}>
                                            <Controller
                                                render={({ field }) => (
                                                    <TextFieldWithCharacterCount
                                                        fullWidth
                                                        required
                                                        label="Email"
                                                        variant="outlined"
                                                        {...field}
                                                        inputProps={{ 'data-testid': TestId.Email, maxLength: 250 }}
                                                    />
                                                )}
                                                name="email"
                                                control={control}
                                            />
                                            {errors.email && <div className={classes.error}>{errors.email.message}</div>}
                                        </Grid>
                                    </Grid>
                                )}

                                <FormControlLabel value={JSON.stringify(ShippingNotificationOption.Sms)} control={<Radio color="primary" />} label="Text SMS" />
                                {field.value === JSON.stringify(ShippingNotificationOption.Sms) && (
                                    <Grid container>
                                        <Grid item xs={12} className={classes.textInput}>
                                            <Controller
                                                render={({ field }) => (
                                                    <PhoneInput
                                                        inputProps={{ 'data-testid': TestId.Sms, maxLength: 50 }}
                                                        {...field}
                                                        country="us"
                                                        countryCodeEditable={false}
                                                        onlyCountries={countryCodes}
                                                    />
                                                )}
                                                name="smsNumber"
                                                control={control}
                                            />
                                            {errors.smsNumber && <div className={classes.error}>{errors.smsNumber.message}</div>}
                                        </Grid>
                                    </Grid>
                                )}
                            </RadioGroup>
                        )}
                        name="notificationOption"
                        control={control}
                    />
                </div>
            </Grid>
        </>
    )
}
