export const TestId = {
    AddressOne: 'addressOne',
    AddressTwo: 'addressTwo',
    AttachImageButton: 'attachImageButton',
    Backdrop: 'backdrop',
    BadgePhotoInstructionsContinueButton: 'badgePhotoInstructionsContinueButton',
    BadgePhotoInstructionsGrid: 'badgePhotoInstructions',
    Campus: 'campus',
    CampusOption: 'campusOption',
    Carrier: 'carrier',
    City: 'city',
    DeviceSelect: 'deviceSelect',
    Email: 'email',
    Footer: 'footer',
    GovtIdContainer: 'govtIdContainer',
    GovtIdTakePhotoContainer: 'govtIdTakePhotoContainer',
    GovtIdTakePhotoOption: 'govtIdTakePhotoOption',
    GovtIdUploadPhotoButton: 'govtIdUploadPhotoButton',
    GovtIdUploadPhotoOption: 'govtIdUploadPhotoOption',
    GovtIdSkipUploadOption: 'govtIdSkipUploadOption',
    GovtIdSkipUploadContinueButton: 'govtIdSkipUploadContinueButton',
    LoginError: 'loginError',
    LoginLastNameInput: 'loginLastNameInput',
    LoginWwIdInput: 'loginWwIdInput',
    LogOutButton: 'logOutButton',
    LogOutMenuItem: 'logOutMenuItem',
    MailStop: 'mailStop',
    NameField: 'nameField',
    NotificationCancelButton: 'notificationCancelButton',
    NotificationContainer: 'notificationContainer',
    NotificationSubmitButton: 'notificationSubmitButton',
    PreferredFirstNameField: 'preferredFirstNameField',
    PrivacyContinueButton: 'privacyContinueButton',
    ProgressCircle: 'progressCircle',
    ReplaceExistingPhotoSummaryEditPhotoButton: 'replaceExistingPhotoSummaryEditPhotoButton',
    ReplaceExistingPhotoSummaryEditShippingButton: 'replaceExistingPhotoSummaryEditShippingButton',
    ReplaceExistingPhotoSummaryFinishButton: 'replaceExistingPhotoSummaryFinishButton',
    RequestNewBadgeSummaryEditGovtIdButton: 'requestNewBadgeSummaryEditGovtIdButton',
    RequestNewBadgeSummaryEditPhotoButton: 'requestNewBadgeSummaryEditPhotoButton',
    RequestNewBadgeSummaryEditShippingButton: 'requestNewBadgeSummaryEditShippingButton',
    RequestNewBadgeSummaryFinishButton: 'requestNewBadgeSummaryFinishButton',
    ShipOption: 'shipOption',
    ShippingCancelButton: 'shippingCancelButton',
    ShippingContainer: 'shippingContainer',
    ShippingNotificationGrid: 'shippingNotificationGrid',
    ShippingReceiveGrid: 'shippingReceiveGrid',
    ShippingSubmitButton: 'shippingSubmitButton',
    SignInButton: 'signInButton',
    SignInLink: 'signInLink',
    Sms: 'sms',
    State: 'state',
    StepFourLink: 'stepFourLink',
    StepOneLink: 'stepOneLink',
    StepThreeLink: 'stepThreeLink',
    StepTwoLink: 'stepTwoLink',
    TakePhotoButton: 'takePhotoButton',
    ViewPhotoContinueButton: 'viewPhotoContinueButton',
    ViewPhotoRetakeButton: 'viewPhotoRetakeButton',
    WorkflowOptionActivateBadge: 'workflowOptionActivateBadge',
    WorkflowOptionReactivateBadge: 'workflowOptionReactivateBadge',
    WorkflowOptionDeactivateBadge: 'workflowOptionDeactivateBadge',
    WorkflowOptionReplaceExistingBadge: 'workflowOptionReplaceExistingBadge',
    WorkflowOptionReplaceExistingPhoto: 'workflowOptionReplaceExistingPhoto',
    WorkflowOptionRequestNewBadge: 'workflowOptionRequestNewBadge',
    WwIdField: 'wwIdField',
    Zip: 'zip',
}
