import React from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { FCWithAuth, FlowWithPayload } from '../types/CommonTypes'
import useStyles from '../styles/useStyles'
import { RequestType, ShippingNotificationOption, ShippingReceiveOption, PhotoSent as constants } from '../constants/Constants'
import { ShippingValues } from 'types/ShippingValuesTypes'
import { ROUTE_PATHS } from 'constants/Routes'
import { parsePhoneNumber } from 'libphonenumber-js'

const getBadgeText = (flowType: string): string => {
    return flowType == RequestType.ReplaceExistingBadge.name || flowType == RequestType.ReplaceExistingPhoto.name ? constants.ReplaceBadge : constants.NewBadge
}

export const PhotoSent: FCWithAuth = () => {
    const location = useLocation()
    const { flow, payload: shippingValues } = location.state as FlowWithPayload<ShippingValues>
    const classes = useStyles()

    if (!shippingValues) {
        const history = useHistory()
        history.push(ROUTE_PATHS.WORKFLOW_OPTIONS)
    }

    const getDescriptionText = () => {
        let receiveText = ''
        if (shippingValues.receiveOption === JSON.stringify(ShippingReceiveOption.Campus)) {
            receiveText = `${getBadgeText(flow)} ${constants.CampusPickup}`
        } else if (shippingValues.receiveOption === JSON.stringify(ShippingReceiveOption.Ship)) {
            receiveText = `${getBadgeText(flow)} ${constants.Ship}`
        }

        const notificationText = `${constants.StatusNotify} ${
            shippingValues.notificationOption === JSON.stringify(ShippingNotificationOption.Email) ? constants.Email : constants.SMS
        }.`

        return `${receiveText} ${notificationText}`.trim()
    }

    return (
        <div className={classes.root}>
            {shippingValues && (
                <Grid container justify="center" className={classes.photoSentContainer}>
                    <Grid item xs={11} sm={12}>
                        <div className={classes.photoSentTitle}>Your photo was sent to Intel for final approval.</div>
                    </Grid>
                    <Grid item xs={11} sm={12}>
                        <div className={classes.photoSentDescription}>{getDescriptionText()}</div>
                    </Grid>
                    {shippingValues.receiveOption === JSON.stringify(ShippingReceiveOption.Campus) && (
                        <>
                            <Grid item xs={12}>
                                {shippingValues.campusName}
                            </Grid>
                            <Grid item xs={12}>
                                {shippingValues.mailStop}
                            </Grid>
                        </>
                    )}
                    {shippingValues.receiveOption === JSON.stringify(ShippingReceiveOption.Ship) && (
                        <>
                            <Grid item xs={12}>
                                {shippingValues.addressOne}
                            </Grid>
                            <Grid item xs={12}>
                                {shippingValues.addressTwo}
                            </Grid>
                            <Grid item xs={12}>
                                {shippingValues.city}, {shippingValues.state} {shippingValues.zip}
                            </Grid>
                        </>
                    )}
                    <Grid item xs={11} sm={12} className={classes.photoSentNotification}>
                        {shippingValues.notificationOption === JSON.stringify(ShippingNotificationOption.Email)
                            ? shippingValues.email
                            : parsePhoneNumber(`+${shippingValues.smsNumber}`).formatNational()}
                    </Grid>
                </Grid>
            )}
        </div>
    )
}

PhotoSent.requiresAuth = true
