import { IntelSingletonHttpClient, keyValuePairs } from '@intel-sms/core-services'
import { v4 } from 'uuid'

const API = {
    config: '/api/config',
}

let configurations: keyValuePairs = {}
let remoteConfigurationLoaded = false

const CONFIG_HEADER = 'x-srpa-id'
export const getConfig = async (): Promise<keyValuePairs> => {
    if (!remoteConfigurationLoaded) {
        configurations = await IntelSingletonHttpClient.getData<keyValuePairs>(API.config, { headers: { [CONFIG_HEADER]: v4() } })
        remoteConfigurationLoaded = true
    }
    return configurations
}
