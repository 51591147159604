import { ErrorMessage, ShippingNotificationOption } from '../constants/Constants'
import { ROUTE_PATHS } from '../constants/Routes'
import { parsePhoneNumber } from 'libphonenumber-js'
import { PasscodeValues } from '../types/PasscodeTypes'
import { passcodeEmail, passcodeSms } from '../services/PasscodeService'
import { IPasscodeEmailRequest, IPasscodeSmsRequest } from '../interfaces/IPasscode'
import { History } from 'history'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

export const handlePasscodeSubmission = async (
    data: PasscodeValues,
    isValid: boolean,
    history: History,
    errorNotifier: (message: SnackbarMessage, options?: OptionsObject | undefined) => SnackbarKey
): Promise<void> => {
    if (isValid) {
        if (data.passcodeOption === ShippingNotificationOption.Sms.name) {
            const number = parsePhoneNumber(data.smsNumber)
            const payload: IPasscodeSmsRequest = {
                wwid: data.wwid,
                countryCode: number.countryCallingCode.toString(),
                mobileNumber: number.nationalNumber.toString(),
            }
            await passcodeSms(payload)
                .then(() => {
                    history.push({ pathname: ROUTE_PATHS.ENTRY_CODE, state: data })
                })
                .catch((e) => errorNotifier(`${ErrorMessage.CodeEntry} ${e.message}`, { variant: 'error' }))
        } else {
            const payload: IPasscodeEmailRequest = {
                wwid: data.wwid,
                emailAddress: data.email,
            }
            await passcodeEmail(payload)
                .then(() => {
                    history.push({ pathname: ROUTE_PATHS.ENTRY_CODE, state: data })
                })
                .catch((e) => errorNotifier(`${ErrorMessage.CodeEntry} ${e.message}`, { variant: 'error' }))
        }
    } else {
        alert('error')
    }
}
