import { AccountInfo, EventType, IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import React, { FC, useEffect, useState } from 'react'
import PageWithLoad from '../components/progress/PageWithLoad'
import useStyles from '../styles/useStyles'
import AzureAuthenticationContext from './AzureAuthenticationContext'

export const AzureMsalProviderWrapper: FC<unknown> = ({ children }) => {
    const classes = useStyles()
    const [msalInstance, setMsalInstance] = useState<IPublicClientApplication>()
    const [setupBypass, setSetupBypass] = useState<boolean>(false)

    const setupClient = async () => {
        try {
            const msalInstance = await AzureAuthenticationContext.getClient()
            if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
                // Account selection logic is app dependent. Adjust as needed for different use cases.
                msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
            }

            // Optional - This will update account state if a user signs in from another tab or window
            msalInstance.enableAccountStorageEvents()

            msalInstance.addEventCallback((event) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && (event?.payload as AccountInfo)?.homeAccountId) {
                    const account = event.payload as AccountInfo
                    msalInstance.setActiveAccount(account)
                }
            })
            setMsalInstance(msalInstance as unknown as IPublicClientApplication)
        } catch (error) {
            console.log(error)
            setSetupBypass(true)
        }
    }
    useEffect(() => {
        if (!msalInstance) {
            setupClient()
        }
        setTimeout(() => {
            setSetupBypass(true)
        }, 5000)
    }, [])
    if (msalInstance) {
        return <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    }
    return setupBypass ? (
        <>{children}</>
    ) : (
        <div className={classes.loginRoot}>
            <PageWithLoad loading={true} />
        </div>
    )
}
