export const ApplicationName = 'Security Remote Photo Acquisition'
export const SUPPORTED_FILE_TYPES = ['.jpg', '.jpeg', '.jpe', '.jif', '.jfif', '.png', '.gif']

export const GenericRemoveBackgroundError = 'Unable to complete photo validation'

export const AuthType = {
    AzureAd: { value: 1, name: 'AzureAd' },
    WwId: { value: 2, name: 'WwId' },
}

export const RequestType = {
    RequestNewBadge: { value: 1, name: 'Request New First Time Badge' },
    ReplaceExistingBadge: { value: 2, name: 'Replace Existing Intel Badge' },
    ReplaceExistingPhoto: { value: 3, name: 'Replace Existing Photo' },
    ActivateBadge: { value: 4, name: 'Activate Intel Badge' },
    DeactivateBadge: { value: 5, name: 'Deactivate Intel Badge' },
    ReactivateBadge: { value: 6, name: 'Reactivate Intel Badge' },
}

export const AzureLoginMessages = {
    AutoLoginInProgress: 'Attempting to automatically login with Azure',
    AttemptingLogin: 'Attempting to login to Azure with popup window.',
    PersonalDevice: 'If using your personal device try ',
    UseWwid: ' or if having issues with Azure use ',
    WwidLoginLink: 'alternate login',
    WwidAppFunction:
        'By logging into the application with an alternate ID, your application access will be limited to only being able to submit a photo and photo ID',
    CheckPopupBlocker: "If you don't see a popup to log in, check your popup blocker or ",
    UseAzure: 'Use your Intel login in order to obtain all available functions of the SRPA application',
    FaqLink: 'Frequently Asked Questions (FAQ)',
    NonAzureLoginTooltip: 'This feature is only available with Intel login',
}

export const StepDescription = {
    ShippingInformation: 'Shipping Information',
    NotificationInformation: 'Notification Information',
    CaptureGovernmentId: 'Capture Government ID',
    TakePhotoForIntelBadge: 'Take Photo for Intel Badge',
    TakeNewPhoto: 'Take New Photo',
    Summary: 'Summary',
    BadgePhoto: 'Photo for Intel Badge',
    GovernmentId: 'Government ID',
}

export const ShippingReceiveOption = {
    Campus: { value: 1, name: 'campus' },
    Ship: { value: 2, name: 'ship' },
}

export const ShippingReceiveOptionText = {
    Campus: 'Pick up at Intel Campus (preferred method)',
    Ship: 'Ship to Alternate Address',
    FirstTime: '(Must submit a Government ID with this option.)',
    NonAzureAuthShipping: 'This function is only available if you use Azure authentication',
}

export const ShippingNotificationOption = {
    Email: { value: 1, name: 'email' },
    Sms: { value: 2, name: 'sms' },
}

export const GovtIdCaptureOption = {
    TakePhoto: 'take',
    AttachImage: 'attach',
    Skip: 'skip',
}

export const GovtIdCaptureOptionText = {
    TakePhoto: 'Take a photo of ID using your camera',
    AttachImage: 'Attach Image',
    Skip: 'Skip uploading a Government ID and pickup your badge at campus.',
}

export const GovtIdSkipText = {
    SkipWarning:
        'Uploading a Government ID is required to have your badge shipped.  By skipping the Government Photo you will need to verify your campus pickup details.',
    SkipContinue: 'Continue',
}

export const PhotoCaptureOption = {
    TakePhoto: 'takephoto',
    AttachImage: 'attachimage',
}

export const BadgePhotoStep = {
    Instructions: 'instructions',
    TakePhoto: 'takephoto',
    AttachImage: 'attachimage',
    ViewPhoto: 'viewphoto',
}

export const ViewPhotoText = {
    GovernmentId: 'Your Government ID has been captured.',
    BadgeId: 'Your photo has met the initial requirements.',
}

export const ErrorMessage = {
    LoginError: 'Login Failed.',
    Email: 'Please enter a valid email address',
    SmsNumber: 'Please enter a valid phone number',
    CodeEntry: 'There was a problem with the code entered: ',
}

export const LoginNotificationOption = {
    Email: 'email',
    Sms: 'sms',
}

export const TwoAuthVerificationText = {
    CodeSent: 'Your code was sent to',
    EnterCode: 'Enter your code as soon as you receive it.',
    ReceivceCode: 'You may not receive your code immediately. Please allow a few minutes for delivery.',
    DidNotReceiveCode: `If you didn't receive your code or if the code you received has expired, you can request a new code.`,
    ChoosingWayToReceive: 'Help us confirm your identity by choosing the option that works best for receiving a code.',
    ConsentForOTP: 'By continuing through the login process you are providing consent to receive email or SMS messages for identity verification purposes.',
}

export const CookieConsentText = {
    Messaging: 'The Security Remote Photo Acquisition application uses cookies to enhance the user experience.',
    AllowBtn: 'Allow Cookies',
    DeclineBtn: 'Decline',
}

export const ActivateBadgeText = {
    Messaging1: `Note: When you activate your new badge, any other Intel 
    Identification badges or temporary access badges assigned to you will be disabled. Please allow `,
    Messaging2: ' minutes for this process to complete prior to attempting to use your new badge.',
    Title: 'A request has been made to activate your badge.',
}

export const ReactivateBadgeText = {
    Messaging1: `Note: When you reactivate your badge, any other Intel 
    Identification badges or temporary access badges assigned to you will be disabled. Please allow `,
    Messaging2: ' minutes for this process to complete prior to attempting to use your badge.',
    Title: 'A request has been made to reactivate your badge.',
}

export const DeactivateBadgeText = {
    Messaging1: `Note: You have submitted a request to Deactivate your badge. Please allow `,
    Messaging2: ' minutes for this process to complete.',
    Title: 'A request has been made to deactivate your badge.',
}

// prettier-ignore
export const Regex = {
    Email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
}

export const Footer = {
    Version: 'Application Version',
    Confidential: 'Intel Confidential',
}

export const PhotoSent = {
    NewBadge: 'A new badge',
    ReplaceBadge: 'A replacement badge',
    CampusPickup: 'will be available for pickup at the selected Campus and Mailstop pending approval.',
    Ship: 'will be sent to the following address pending approval.',
    StatusNotify: 'You will be notified of your status via',
    Email: 'email',
    SMS: 'sms',
}
