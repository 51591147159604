import React, { FC, useContext } from 'react'
import { NavBar } from './NavBar'
import { Footer } from './Footer'
import { AppContext } from '../AppContext'
import useStyles from '../styles/useStyles'

export const PageLayout: FC<unknown> = ({ children }) => {
    const { user, isAuthenticated } = useContext(AppContext)

    const classes = useStyles()

    const authenticated = isAuthenticated()
    return (
        <div className={classes.site}>
            <div className={classes.siteContent}>
                {authenticated && user && user.requiresMFA === false && <NavBar />}
                {children}
            </div>
            {<Footer showLinks={authenticated} />}
        </div>
    )
}
