import React, { FC } from 'react'

import { Button, Grid } from '@material-ui/core'

import successCheckmark from '../images/SuccessCheckmark.svg'
import fail from '../images/fail.png'
import useStyles from '../styles/useStyles'
import { TestId } from 'constants/testIds'
import { GenericRemoveBackgroundError } from 'constants/Constants'

interface IProps {
    capturedImage: string | null
    inValidImageReason: string
    validImageText: string
    handleContinue: (image: string | null) => void
    handleRetakePhoto: () => void
}

export const ViewPhotoComponent: FC<IProps> = ({ capturedImage, inValidImageReason, validImageText, handleContinue, handleRetakePhoto }) => {
    const classes = useStyles()
    const isGenericError = inValidImageReason == GenericRemoveBackgroundError
    return (
        <div className={classes.viewPhotoTop}>
            {inValidImageReason.length === 0 && (
                <>
                    <div>
                        <img src={successCheckmark} alt=" " />
                        <span className={classes.viewPhotoStatusText}>Success</span>
                    </div>
                    <div className={classes.viewPhotoText}>{validImageText}</div>
                </>
            )}
            {inValidImageReason.length > 0 && (
                <>
                    <div>
                        <img src={fail} alt=" " />
                        <span className={classes.viewPhotoStatusText}>Please try again</span>
                    </div>
                    <div className={classes.viewPhotoText}>
                        {!isGenericError && 'Your photo did not meet the requirements.'}
                        {!isGenericError && <br />}
                        <b>{inValidImageReason}</b>
                    </div>
                </>
            )}
            <Grid container justify="center" spacing={1}>
                {inValidImageReason.length === 0 && (
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.viewPhotoButton}
                            onClick={() => handleContinue(capturedImage)}
                            data-testid={TestId.ViewPhotoContinueButton}
                        >
                            Continue
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    <Button
                        variant={inValidImageReason.length === 0 ? 'outlined' : 'contained'}
                        color="primary"
                        className={classes.viewPhotoButton}
                        onClick={handleRetakePhoto}
                        data-testid={TestId.ViewPhotoRetakeButton}
                    >
                        Retake Photo
                    </Button>
                </Grid>
            </Grid>
            {capturedImage && (
                <div className={classes.photoBorder}>
                    <img src={capturedImage} alt=" " className={classes.viewPhotoCapturedImage} />
                </div>
            )}
        </div>
    )
}
