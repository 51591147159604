import React, { FC, useContext } from 'react'
import { Grid } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

import { AppContext } from '../AppContext'
import { DisabledTextField } from './DisabledTextField'
import useStyles from '../styles/useStyles'
import { ShippingValues } from '../types/ShippingValuesTypes'
import { RequestType, StepDescription } from '../constants/Constants'
import { TestId } from 'constants/testIds'
import TextFieldWithCharacterCount from './TextFieldWithCharacterCount'

interface IProps {
    requestType: number
}

export const ShippingInfoComponent: FC<IProps> = ({ requestType }) => {
    const { user } = useContext(AppContext)
    const classes = useStyles()

    const { control } = useFormContext<ShippingValues>()

    return (
        <div>
            <Grid container className={classes.textInput}>
                <Grid item xs={12}>
                    <div className={classes.title}>
                        <h1>
                            {requestType === RequestType.ReplaceExistingPhoto.value
                                ? StepDescription.NotificationInformation
                                : StepDescription.ShippingInformation}
                        </h1>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <DisabledTextField disabled fullWidth value={user?.wwId} label="WWID" variant="filled" inputProps={{ 'data-testid': TestId.WwIdField }} />
                </Grid>
            </Grid>
            <Grid container className={classes.textInput}>
                <Grid item xs={12}>
                    <DisabledTextField
                        disabled
                        fullWidth
                        value={user?.firstName + ' ' + user?.lastName}
                        label="Name"
                        variant="filled"
                        inputProps={{ 'data-testid': TestId.NameField }}
                    />
                </Grid>
            </Grid>
            {requestType === RequestType.RequestNewBadge.value && (
                <Grid container className={classes.textInput}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field }) => (
                                <TextFieldWithCharacterCount
                                    fullWidth
                                    label="Preferred First Name"
                                    variant="outlined"
                                    {...field}
                                    inputProps={{ 'data-testid': TestId.PreferredFirstNameField, maxLength: 40 }}
                                    isEnglishOnly
                                />
                            )}
                            name="preferredFirstName"
                            control={control}
                        />
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
