import React from 'react'
import { Grid } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { FCWithAuth } from '../types/CommonTypes'

export const BadgeOffices: FCWithAuth = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container justify="center">
                <Grid item xs={12} lg={4}>
                    <div className={classes.title}>
                        <h1>Intel Regional Badge Offices</h1>
                    </div>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={11} sm={10} md={8} lg={6} xl={4}>
                    <p>
                        <b>China Badge Office Location: SHZ1 Lobby</b>
                        <br />
                        Address: No. 880 Zi Xing Road, Zizhu Science Park, Minhang, Shanghai, 200241 China
                        <br />
                        Phone: 86-21-6116-7500 Email Contact: <a href={'mailto: SHZ.Security.Admin@intel.com'}>SHZ.Security.Admin@intel.com</a>
                    </p>
                    <p>
                        <b>EMEA Badge Office Location: IGK1 Room 251</b>
                        <br />
                        Address: Ul. Slowackiego 173, 80-298 Gdansk, Poland
                        <br />
                        Phone: 00-48-58-766-1891 Email Contact: <a href={'mailto: emea.central.badge.office@intel.com'}>emea.central.badge.office@intel.com</a>
                    </p>
                    <p>
                        <b>India Badge Office Location: SRR1</b>
                        <br />
                        Address: Devarabisanahalli Village,Sarjapur Ring Road, Bangalore, 560103, India
                        <br />
                        Phone: 91-80-6211-3408 Email Contact: <a href={'mailto: indiasecurity@intel.com'}>indiasecurity@intel.com</a>
                    </p>
                    <p>
                        <b>Israel, Haifa Badge Office Location: IDC1</b>
                        <br />
                        Address: Andrei Sakharov 3, PO Box 1659 Matam Industrial Park Haifa 31015, Israel
                        <br />
                        Phone: 00-972-4-8152552 Email Contact: <a href={'mailto: idc.badge.room@intel.com'}>idc.badge.room@intel.com</a>
                    </p>
                    <p>
                        <b>Malaysia Badge Office Location: PG12 L1</b>
                        <br />
                        Address: Intel Technology Sdn, Bhd, Halaman Kampung, Jawa, Bayan Lepas, Free Industrial, Zone, Phase 3, 11900, Bayan Lepas, Pulau,
                        Pinang, Malaysia
                        <br />
                        Phone: 604-253-5591 Email Contact: <a href={'mailto: pg_badgeadmin@intel.com'}>pg_badgeadmin@intel.com</a>
                    </p>
                    <p>
                        <b>North America Badge Office Location: AL3-2</b>
                        <br />
                        Address: Aloha Campus 3585 S.W. 198th Avenue, Aloha, OR 97007, United States
                        <br />
                        Phone: 971-215-9900 Email Contact: <a href={'mailto: security.eam.administration@intel.com'}>security.eam.administration@intel.com</a>
                    </p>
                    <p>
                        <b>South America / Costa Rica</b>
                        <br />
                        Address: Belen Campus Calle 129, La Ribera, Belen, Heredia, Costa Rica
                        <br />
                        Hours of Operation: M-F 8:00am-5:00pm
                        <br />
                        Phone: (506) 2298 7908 Email Contact: <a href={'mailto: cr.badge.and.key@intel.com'}>cr.badge.and.key@intel.com</a>
                    </p>
                </Grid>
            </Grid>
        </div>
    )
}

// This can not be true because it launches in new window and triggers auth again
BadgeOffices.requiresAuth = false
