import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'

// Create a theme instance.
export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0068B5',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#1173C2',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
})
