import { TextField, withStyles } from '@material-ui/core'

export const DisabledTextField = withStyles({
    root: {
        border: '1px solid #AAAAAA',
        borderRadius: '4px',
        '& .MuiFilledInput-underline:before': {
            borderBottom: 'none',
        },
    },
})(TextField)
