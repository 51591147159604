import { Skeleton } from '@material-ui/lab'
import React, { FC, ReactElement } from 'react'
import LoadingSpinnerBackdrop from './LoadingSpinner'

interface IProps {
    loading: boolean
}

const PageWithLoad: FC<IProps> = ({ loading, children }) => {
    if (loading) {
        return <PageSkeleton />
    }
    return <>{children}</>
}

const PageSkeleton = (): ReactElement => {
    return (
        <>
            <Skeleton variant="rect" width={'100%'} height={250} />
            <LoadingSpinnerBackdrop />
        </>
    )
}
export default PageWithLoad
