import React, { FC, useContext } from 'react'
import { Grid } from '@material-ui/core'

import { AppContext } from '../AppContext'
import { SignInLink } from '../components/SignInLink'
import useStyles from '../styles/useStyles'
import { TestId } from '../constants/testIds'

interface IProps {
    login: () => Promise<void>
}

const AzureAuthenticationComponent: FC<IProps> = ({ login }) => {
    const { authenticationModule, isAuthenticated, updateAuthentication, azureAccountInfo } = useContext(AppContext)

    const classes = useStyles()

    const logout = (): void => {
        if (azureAccountInfo) {
            // Azure Logout
            authenticationModule.logout(azureAccountInfo)
            updateAuthentication(null, null)
        }
    }

    const showLogInLink = () => {
        return <SignInLink handleLogin={login} />
    }

    const showLogOutButton = () => {
        return (
            <div>
                <button data-testid={TestId.LogOutButton} onClick={logout}>
                    Log out
                </button>
            </div>
        )
    }

    return (
        <Grid container justify="center">
            <Grid item xs={8} className={classes.loginText}>
                <div>Have a Microsoft Azure® account? {isAuthenticated() ? showLogOutButton() : showLogInLink()}</div>
            </Grid>
        </Grid>
    )
}

export default AzureAuthenticationComponent
