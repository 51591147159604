import { IntelSingletonHttpClient } from '@intel-sms/core-services'
import { IImageProcessingRequest } from '../interfaces/IImageProcessingRequest'
import { INewBadgeRequest } from '../interfaces/INewBadgeRequest'
import { Endpoints } from './Endpoints'
import { EndpointMethods } from './Constants'
import { IReplaceExistingPhotoRequest } from 'interfaces/IReplaceExistingPhotoRequest'
import { IActivateBadgeRequest } from 'interfaces/IActivateBadgeRequest'

export const validateAndCropImage = async (payload: IImageProcessingRequest): Promise<string> => {
    return await IntelSingletonHttpClient.postData(Endpoints.imageEndpoint + EndpointMethods.RemoveBackground, payload)
}

export const createNewBadgeRequest = async (payload: INewBadgeRequest): Promise<string> => {
    return await IntelSingletonHttpClient.postData(Endpoints.imageEndpoint, payload)
}

export const createReplaceExistingPhotoRequest = async (payload: IReplaceExistingPhotoRequest): Promise<string> => {
    return await IntelSingletonHttpClient.postData(Endpoints.imageEndpoint + EndpointMethods.ReplaceExistingPhotoRequest, payload)
}

export const activateBadgeRequest = async (payload: IActivateBadgeRequest): Promise<string> => {
    return await IntelSingletonHttpClient.postData(Endpoints.imageEndpoint + EndpointMethods.ActiveBadge, payload)
}
