import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './styles/theme'
import App from './App'

import { AppProvider } from './AppContext'
import { AzureMsalProviderWrapper } from './azure/AzureMsalProviderWrapper'

ReactDOM.render(
    <Router>
        <ThemeProvider theme={theme}>
            <AzureMsalProviderWrapper>
                <AppProvider>
                    <App />
                </AppProvider>
            </AzureMsalProviderWrapper>
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
)
