import { useContext, useState } from 'react'
import { validateAndCropImage } from '../services/ImageService'
import { IImageProcessingRequest } from '../interfaces/IImageProcessingRequest'
import { AppContext } from 'AppContext'
import { SUPPORTED_FILE_TYPES } from 'constants/Constants'

export type ImageProcessor = {
    isProcessing: boolean
    processingError: string
    invalidImageReason: string
    capturedImage: string | null
    attachLocalFile: (file: File) => void | Promise<void>
    setImage: (unprocessedImage: string | null) => Promise<void>
    retakePhoto: () => void
}

export const useImageProcessing = (requiresValidating = true): ImageProcessor => {
    const { user } = useContext(AppContext)
    const [isRemoteProcessing, setIsRemoteProcessing] = useState<boolean>(false)
    const [processingError, setProcessingError] = useState<string>('')
    const [capturedImage, setCapturedImage] = useState<string | null>('')
    const [invalidImageReason, setInvalidImageReason] = useState<string>('')

    const setImage = async (unprocessedImage: string | null) => {
        if (unprocessedImage) {
            if (requiresValidating) {
                setIsRemoteProcessing(true)
                setProcessingError('')
                try {
                    const wwid: string = user?.wwId ?? ''
                    const data: IImageProcessingRequest = { wwId: wwid, image: unprocessedImage }
                    let processedImage = await validateAndCropImage(data)
                    if (processedImage.startsWith('ERROR - ')) {
                        setInvalidImageReason(processedImage.substr(8))
                        setCapturedImage(unprocessedImage)
                    } else {
                        processedImage = 'data:image/jpeg;base64,' + processedImage
                        setInvalidImageReason('')
                        setCapturedImage(processedImage)
                    }
                    // eslint-disable-next-line
                } catch (error: any) {
                    setInvalidImageReason(error?.message ?? 'Unable to complete photo validation')
                    setCapturedImage('')
                    setProcessingError('Photo could not be processed. Try again later.')
                }
                setIsRemoteProcessing(false)
            } else {
                setCapturedImage(unprocessedImage)
            }
        }
    }

    const attachLocalFile = (file: File) => {
        const filename = file.name.toLowerCase()
        const filesize = file.size
        const resultType = SUPPORTED_FILE_TYPES.find((fileType) => filename.endsWith(fileType))
        if (resultType) {
            if (filesize <= 5000000) {
                readFileDataAsBase64(file)
            } else {
                setInvalidImageReason('Selected Image file exceeds the file size limit of 5mb. Please reduce the size and try again')
            }
        } else {
            setInvalidImageReason(`Selected Image does not match one of the supported file types: ${SUPPORTED_FILE_TYPES.join(', ')}`)
        }
    }

    const readFileDataAsBase64 = (file: File) => {
        const reader = new FileReader()
        reader.onloadend = function () {
            setImage(reader.result as string)
        }
        reader.readAsDataURL(file)
    }

    const retakePhoto = () => {
        setCapturedImage('')
        setInvalidImageReason('')
    }

    return {
        isProcessing: isRemoteProcessing,
        processingError,
        invalidImageReason,
        capturedImage,
        attachLocalFile,
        setImage,
        retakePhoto,
    }
}
