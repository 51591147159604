import React, { FC } from 'react'
import { Container, Button, Grid } from '@material-ui/core'

import { ShippingInfoComponent } from './ShippingInfoComponent'
import { ShippingReceiveComponent } from './ShippingReceiveComponent'
import { ShippingNotificationComponent } from './ShippingNotificationComponent'
import { RequestType } from 'constants/Constants'
import { TestId } from '../constants/testIds'

interface IProps {
    handleCancel: () => void
    handleReceiveOptionChange: () => void
    requestType?: number
}

export const Shipping: FC<IProps> = ({ handleCancel, handleReceiveOptionChange, requestType }) => {
    return (
        <Container maxWidth="sm" data-testid={TestId.ShippingContainer}>
            <Grid item xs={12}>
                <ShippingInfoComponent requestType={RequestType.RequestNewBadge.value} />
            </Grid>
            <Grid item xs={12}>
                <ShippingReceiveComponent handleOptionChange={handleReceiveOptionChange} requestType={requestType} />
            </Grid>
            <Grid item xs={12}>
                <ShippingNotificationComponent />
            </Grid>
            <hr />
            <Grid container>
                <Grid item xs={3} sm={2}>
                    <Button variant="contained" color="primary" type="submit" data-testid={TestId.ShippingSubmitButton}>
                        Submit
                    </Button>
                </Grid>
                <Grid item xs={3} sm={2}>
                    <Button color="primary" onClick={handleCancel} data-testid={TestId.ShippingCancelButton}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
