import React, { FC, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import CookieConsent from 'react-cookie-consent'
import Grid from '@material-ui/core/Grid'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { ROUTE_PATHS } from './constants/Routes'
import { PageLayout } from './components/PageLayout'
import { AuthenticatedRoute } from './AuthenticatedRoute'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { LoginCode } from './pages/LoginCode'
import { CodeEntry } from './pages/CodeEntry'
import { Privacy } from './pages/Privacy'
import { BadgeOffices } from './pages/BadgeOffices'
import { WorkflowOptions } from './pages/WorkflowOptions'
import { RequestNewBadge } from './pages/RequestNewBadge'
import { ReplaceExistingPhoto } from './pages/ReplaceExistingPhoto'
import { ReplaceExistingBadge } from './pages/ReplaceExistingBadge'
import { PhotoSent } from './pages/PhotoSent'
import { ActivateBadge } from './pages/ActivateBadge'

import './styles/App.css'
import { theme } from 'styles/theme'
import { CookieConsentText } from 'constants/Constants'
import { AppContext } from 'AppContext'
import { ReactivateBadge } from 'pages/ReactivateBadge'
import { DeactivateBadge } from 'pages/DeactivateBadge'

const App: FC = () => {
    const { isAuthenticated } = useContext(AppContext)
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
            <PageLayout>
                <Grid container justify="center">
                    <Pages />
                    {isAuthenticated() && (
                        <CookieConsent
                            location="bottom"
                            buttonText={CookieConsentText.AllowBtn}
                            enableDeclineButton
                            declineButtonText={CookieConsentText.DeclineBtn}
                            style={{ background: fade(theme.palette.common.black, 0.8) }}
                            buttonStyle={{ background: theme.palette.primary.main, color: theme.palette.common.white }}
                            expires={365}
                        >
                            {CookieConsentText.Messaging}
                        </CookieConsent>
                    )}
                </Grid>
            </PageLayout>
        </SnackbarProvider>
    )
}

function Pages() {
    return (
        <Switch>
            <AuthenticatedRoute exact path={ROUTE_PATHS.WORKFLOW_OPTIONS} component={WorkflowOptions} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.REQUEST_NEW_BADGE} component={RequestNewBadge} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.REPLACE_EXISTING_BADGE} component={ReplaceExistingBadge} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.REPLACE_EXISTING_PHOTO} component={ReplaceExistingPhoto} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.ACTIVATE_BADGE} component={ActivateBadge} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.REACTIVATE_BADGE} component={ReactivateBadge} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.DEACTIVATE_BADGE} component={DeactivateBadge} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.PHOTO_SENT} component={PhotoSent} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.BADGE_OFFICES} component={BadgeOffices} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.PRIVACY} component={Privacy} />
            <Route exact path={ROUTE_PATHS.HOME} component={Home} />
            <Route exact path={ROUTE_PATHS.LOGIN} component={Login} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.LOGIN_CODE} component={LoginCode} />
            <AuthenticatedRoute exact path={ROUTE_PATHS.ENTRY_CODE} component={CodeEntry} />
            <Redirect from="/" to={ROUTE_PATHS.HOME} />
        </Switch>
    )
}

export default App
