import { IntelSingletonHttpClient } from '@intel-sms/core-services'
import { ILoginRequest } from '../interfaces/ILoginRequest'
import { IUser } from '../interfaces/IUser'
import { IAzureLoginRequest } from '../interfaces/IAzureLoginRequest'
import { Endpoints } from './Endpoints'
import { EndpointMethods } from './Constants'

const PERF_AUTH_HEADER = 'x-srpa-perf-auth'
export const loginWithWwIdLastName = async (payload: ILoginRequest, isPerformanceAuth: boolean): Promise<IUser> => {
    const response: IUser = (await IntelSingletonHttpClient.postData(Endpoints.userEndpoint + EndpointMethods.LoginWithWwId, payload, {
        headers: { [PERF_AUTH_HEADER]: isPerformanceAuth },
    })) as IUser
    return response
}

export const loginWithAzure = async (azureToken: string): Promise<IUser> => {
    const data: IAzureLoginRequest = { azureToken }
    const response: IUser = (await IntelSingletonHttpClient.postData(Endpoints.userEndpoint + EndpointMethods.LoginWithAzure, data)) as IUser
    return response
}
