import { keyValuePairs } from '@intel-sms/core-services'
import { useEffect, useState } from 'react'
import { getConfig } from 'services/ConfigService'

export const useConfig = (): keyValuePairs => {
    const [configData, setConfigData] = useState<keyValuePairs>({})

    useEffect(() => {
        getConfig().then((remoteConfigData) => setConfigData(remoteConfigData))
    }, [])
    return configData
}
