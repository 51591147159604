import { isPossiblePhoneNumber } from 'libphonenumber-js'

export const isPhoneNumberValid = (phoneNumberData?: string): boolean => {
    if (phoneNumberData) {
        const countryCode = phoneNumberData?.charAt(0)
        const phoneNumber = phoneNumberData.substring(1)
        return isPossiblePhoneNumber(phoneNumber, { defaultCallingCode: countryCode })
    }
    return false
}
