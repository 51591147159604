import React, { FC } from 'react'
import { AppBar, Toolbar } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { ROUTE_PATHS } from '../constants/Routes'
import { ApplicationName } from 'constants/Constants'
import { SignOutButton } from './SignOutButton'
import useStyles from '../styles/useStyles'

export const NavBar: FC<unknown> = () => {
    const classes = useStyles()

    return (
        <div>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <div className={classes.navBarTitle}>
                        <Link to={ROUTE_PATHS.WORKFLOW_OPTIONS} className={classes.navBarLink}>
                            <h4>{ApplicationName}</h4>
                        </Link>
                    </div>
                    <SignOutButton />
                </Toolbar>
            </AppBar>
        </div>
    )
}
