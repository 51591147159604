import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Grid } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { ROUTE_PATHS } from '../constants/Routes'
import { FCWithAuth } from '../types/CommonTypes'
import { TestId } from 'constants/testIds'

export const Privacy: FCWithAuth = () => {
    const classes = useStyles()

    const history = useHistory()

    const handleContinue = () => {
        history.push(ROUTE_PATHS.WORKFLOW_OPTIONS)
    }

    return (
        <div className={classes.root}>
            <Grid container justify="center">
                <Grid item xs={9}>
                    <div className={classes.title}>
                        <h1>Your Privacy is Important to Intel</h1>
                    </div>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={9}>
                    <h4>Privacy Notice</h4>
                </Grid>
                <Grid item xs={9}>
                    <p>
                        Intel Corporation collects personal data for the purpose of issuing you an Intel badge without your need to visit an Intel badge office.
                        Intel requires that your identification is confirmed prior to issuing your badge. If you choose not to provide a copy of your government
                        issued identification via this app, you will need to coordinate with your regional badge office to have your badge issued. Look for
                        badge office locations{' '}
                        <Link to={ROUTE_PATHS.BADGE_OFFICES} target="_blank">
                            here
                        </Link>
                        .
                    </p>
                    <p>
                        For information on how Intel handles your personal data see{' '}
                        <a href="https://www.intel.com/content/www/us/en/privacy/global-employee-cw-privacy-notice.html" target="_blank">
                            Intel Global Employee and Contingent Worker Privacy.
                        </a>
                    </p>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={3} sm={2} md={1}>
                    <Button variant="contained" color="primary" onClick={handleContinue} data-testid={TestId.PrivacyContinueButton}>
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

Privacy.requiresAuth = true
