import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { StepsComponent } from '../components/StepsComponent'
import { Shipping } from '../components/Shipping'
import { GovernmentId } from '../components/GovernmentId'
import { BadgePhoto } from '../components/BadgePhoto'
import { RequestNewBadgeSummary } from '../components/RequestNewBadgeSummary'

import useStyles from '../styles/useStyles'
import { RequestType, ShippingNotificationOption, ShippingReceiveOption, StepDescription } from '../constants/Constants'
import { FCWithAuth, FlowWithPayload } from '../types/CommonTypes'
import { ImageTypes } from '../types/ImageTypes'
import { ROUTE_PATHS } from '../constants/Routes'
import { createNewBadgeRequest } from '../services/ImageService'
import { INewBadgeRequest } from '../interfaces/INewBadgeRequest'
import { AppContext } from '../AppContext'
import { RequestNewBadgeType } from '../types/RequestNewBadgeType'
import { ShippingValues } from '../types/ShippingValuesTypes'
import { CompletedStepTypes } from '../types/CompletedStepTypes'
import { isPhoneNumberValid } from 'utils/ValidationUtils'

export const RequestNewBadge: FCWithAuth = () => {
    const { user } = useContext(AppContext)
    const classes = useStyles()

    const initialValues: RequestNewBadgeType = {
        shippingValues: {
            preferredFirstName: '',
            receiveOption: JSON.stringify(ShippingReceiveOption.Campus),
            campusName: user?.campus ?? '',
            mailStop: user?.mailStop ?? '',
            addressOne: '',
            addressTwo: '',
            city: '',
            state: '',
            zip: '',
            notificationOption: JSON.stringify(ShippingNotificationOption.Email),
            email: user?.email ?? '',
            smsNumber: '',
        },
        setShippingValues: () => {},
        images: {
            governmentId: '',
            badgePhoto: '',
        },
        setImages: () => {},
        completedSteps: {
            shippingValues: false,
            govtIdImage: false,
            badgeImage: false,
        },
        setCompletedSteps: () => {},
    }

    const [shippingValues, setShippingValues] = useState<ShippingValues>(initialValues.shippingValues)
    const [images, setImages] = useState<ImageTypes>(initialValues.images)
    const [completedSteps, setCompletedSteps] = useState<CompletedStepTypes>(initialValues.completedSteps)

    const formMethods = useForm<ShippingValues>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: shippingValues,
    })

    const [currentStep, setCurrentStep] = useState<number>(1)
    const [previousStep, setPreviousStep] = useState<number>(0)
    const [stepCount, setStepCount] = useState<number>(3)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const history = useHistory()

    const onShippingSubmit: SubmitHandler<ShippingValues> = (data) => {
        if (validateShipping()) {
            setShippingValues(data)
            handleContinue()
        }
    }

    const handleReceiveOptionChange = () => {
        setPreviousStep(0)
        if (formMethods.getValues('receiveOption') === JSON.stringify(ShippingReceiveOption.Campus)) {
            setStepCount(4)
            formMethods.setValue('receiveOption', JSON.stringify(ShippingReceiveOption.Ship))
        } else {
            setStepCount(3)
            formMethods.setValue('receiveOption', JSON.stringify(ShippingReceiveOption.Campus))
        }
    }

    const validateShipping = (): boolean => {
        let isValid = true
        if (formMethods.getValues('receiveOption') === JSON.stringify(ShippingReceiveOption.Campus)) {
            if (formMethods.getValues('campusName').trim().length === 0) {
                formMethods.setError('campusName', { type: 'manual', message: 'Required' })
                isValid = false
            }
        } else {
            if (formMethods.getValues('addressOne').trim().length === 0) {
                formMethods.setError('addressOne', { type: 'manual', message: 'Required' })
                isValid = false
            }
            if (formMethods.getValues('city').trim().length === 0) {
                formMethods.setError('city', { type: 'manual', message: 'Required' })
                isValid = false
            }
            if (formMethods.getValues('state').trim().length === 0) {
                formMethods.setError('state', { type: 'manual', message: 'Required' })
                isValid = false
            }
            if (formMethods.getValues('zip').trim().length === 0) {
                formMethods.setError('zip', { type: 'manual', message: 'Required' })
                isValid = false
            }
        }

        if (formMethods.getValues('notificationOption') === JSON.stringify(ShippingNotificationOption.Email)) {
            if (formMethods.getValues('email').trim().length === 0) {
                formMethods.setError('email', { type: 'manual', message: 'Required' })
                isValid = false
            }
        } else {
            if (formMethods.getValues('smsNumber').trim().length === 0) {
                formMethods.setError('smsNumber', { type: 'manual', message: 'Required' })
                isValid = false
            } else if (!isPhoneNumberValid(formMethods.getValues('smsNumber'))) {
                formMethods.setError('smsNumber', { type: 'manual', message: 'Invalid Phone Number' })
                isValid = false
            }
        }

        return isValid
    }

    const getStepDescription = (step: number): string => {
        switch (step) {
            case 1:
                return StepDescription.ShippingInformation
            case 2:
                return stepCount === 4 ? StepDescription.CaptureGovernmentId : StepDescription.TakePhotoForIntelBadge
            case 3:
                return stepCount === 4 ? StepDescription.TakePhotoForIntelBadge : StepDescription.Summary
            case 4:
                return StepDescription.Summary
            default:
                return ''
        }
    }

    const handleCancel = () => {
        if (previousStep > 0) {
            setCurrentStep(previousStep)
            setPreviousStep(0)
        } else {
            history.push(ROUTE_PATHS.WORKFLOW_OPTIONS)
        }
    }

    const handleContinue = () => {
        if (previousStep > 0) {
            setCurrentStep((step) => step + 1)
            setPreviousStep((step) => step + 1)
        } else {
            setPreviousStep(currentStep)
            setStepComplete(currentStep)
            setCurrentStep(currentStep + 1)
        }
    }

    const handleGovtIdContinue = (image: string | null) => {
        if (image) {
            const newImages: ImageTypes = { governmentId: image, badgePhoto: images.badgePhoto }
            setImages(newImages)
            handleContinue()
        } else {
            //This will move us back to shipping into then switch it to campus
            handleCancel()
            handleReceiveOptionChange()
        }
    }

    const handleBadgePhotoContinue = (image: string | null) => {
        if (image) {
            const newImages: ImageTypes = { governmentId: images.governmentId, badgePhoto: image }
            setImages(newImages)
            handleContinue()
        }
    }

    const handleFinishAndSend = async () => {
        setIsProcessing(true)

        const payload: INewBadgeRequest = {
            wwId: user?.wwId,
            photoIntakeAuthenticationTypeID: user?.photoIntakeAuthenticationTypeID,
            photoIntakeAuthenticationTypeName: user?.photoIntakeAuthenticationTypeName,
            photoIntakeRequestTypeID: RequestType.RequestNewBadge.value,
            photoIntakeRequestTypeName: RequestType.RequestNewBadge.name,
            governmentIdPhoto: images.governmentId,
            badgePhoto: images.badgePhoto,
            firstName: user?.firstName,
            lastName: user?.lastName,
            preferredFirstName: shippingValues.preferredFirstName,
            photoIntakeShippingTypeID: JSON.parse(shippingValues.receiveOption).value,
            photoIntakeShippingTypeName: JSON.parse(shippingValues.receiveOption).name,
            campusName: shippingValues.campusName,
            mailStop: shippingValues.mailStop,
            addressOne: shippingValues.addressOne,
            addressTwo: shippingValues.addressTwo,
            city: shippingValues.city,
            state: shippingValues.state,
            zip: shippingValues.zip,
            photoIntakeNotificationTypeID: JSON.parse(shippingValues.notificationOption).value,
            photoIntakeNotificationTypeName: JSON.parse(shippingValues.notificationOption).name,
            email: shippingValues.email,
            smsNumber: shippingValues.smsNumber,
        }
        try {
            const response = await createNewBadgeRequest(payload)
            if (response.startsWith('ERROR - ')) {
                alert('handleFinishAndSend - API error')
            } else {
                const payload: FlowWithPayload<ShippingValues> = { flow: RequestType.ReplaceExistingBadge.name, payload: shippingValues }
                history.push({ pathname: ROUTE_PATHS.PHOTO_SENT, state: payload })
            }
        } catch (error) {
            console.log('handleFinishAndSend-error', error)
        } finally {
            setIsProcessing(false)
        }
    }

    const handleEditShippingInfo = async () => {
        setCurrentStep(1)
        setPreviousStep(stepCount)
    }

    const handleEditGovtPhoto = async () => {
        setCurrentStep(2)
        setPreviousStep(stepCount)
    }

    const handleEditBadgePhoto = async () => {
        setCurrentStep(stepCount - 1)
        setPreviousStep(stepCount)
    }

    const validateStepSelection = (requestedStep: number) => {
        if (requestedStep === 1) {
            return true
        }

        const stepDescription = getStepDescription(requestedStep)
        if (stepDescription === StepDescription.CaptureGovernmentId && completedSteps.shippingValues) {
            // Make sure shipping info is complete
            return true
        }

        if (stepDescription === StepDescription.TakePhotoForIntelBadge) {
            // Make sure shipping info is complete
            if (stepCount === 3 && completedSteps.shippingValues) {
                return true
            }
            // Make sure govt Id image has been captured
            if (stepCount === 4 && completedSteps.govtIdImage) {
                return true
            }
        }

        if (stepDescription === StepDescription.Summary) {
            // Make sure badge image has been captured
            if (stepCount === 3 && completedSteps.badgeImage) {
                return true
            }
            // Make sure govt Id and badge image have been captured
            if (stepCount === 4 && completedSteps.govtIdImage && completedSteps.badgeImage) {
                return true
            }
        }

        return false
    }

    const setStepComplete = (step: number) => {
        let steps: CompletedStepTypes
        const stepDescription = getStepDescription(step)
        switch (stepDescription) {
            case StepDescription.ShippingInformation:
                steps = { shippingValues: true, govtIdImage: completedSteps.govtIdImage, badgeImage: completedSteps.badgeImage }
                break

            case StepDescription.CaptureGovernmentId:
                steps = { shippingValues: true, govtIdImage: true, badgeImage: completedSteps.badgeImage }
                break

            case StepDescription.TakePhotoForIntelBadge:
                steps = { shippingValues: true, govtIdImage: completedSteps.govtIdImage, badgeImage: true }
                break

            default:
                // Shouldn't be able to get here
                steps = { shippingValues: false, govtIdImage: false, badgeImage: false }
                break
        }

        setCompletedSteps(steps)
    }

    return (
        <div className={classes.root}>
            <StepsComponent
                stepCount={stepCount}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                getStepDescription={getStepDescription}
                validateStepSelection={validateStepSelection}
                validateShipping={validateShipping}
            />
            {getStepDescription(currentStep) === StepDescription.ShippingInformation && (
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onShippingSubmit)}>
                        <Shipping
                            handleCancel={handleCancel}
                            handleReceiveOptionChange={handleReceiveOptionChange}
                            requestType={RequestType.RequestNewBadge.value}
                        />
                    </form>
                </FormProvider>
            )}
            {getStepDescription(currentStep) === StepDescription.CaptureGovernmentId && <GovernmentId handleViewPhotoContinue={handleGovtIdContinue} />}
            {getStepDescription(currentStep) === StepDescription.TakePhotoForIntelBadge && <BadgePhoto handleViewPhotoContinue={handleBadgePhotoContinue} />}
            {getStepDescription(currentStep) === StepDescription.Summary && (
                <RequestNewBadgeSummary
                    isProcessing={isProcessing}
                    isNew={true}
                    shippingValues={shippingValues}
                    images={images}
                    handleFinishAndSend={handleFinishAndSend}
                    handleEditShippingInfo={handleEditShippingInfo}
                    handleEditGovtPhoto={handleEditGovtPhoto}
                    handleEditBadgePhoto={handleEditBadgePhoto}
                />
            )}
        </div>
    )
}

RequestNewBadge.requiresAuth = true
