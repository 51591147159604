import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'

import { Backdrop, Button, CircularProgress, FormControl, Grid, InputLabel, Select } from '@material-ui/core'
import PhotoCamera from '@material-ui/icons/PhotoCamera'

import useStyles from '../styles/useStyles'
import { TestId } from 'constants/testIds'
import { ImageCaptureSupport, RemotelyProcessedImageProps } from 'types/ImageTypes'

export const TakePhotoComponent: FC<RemotelyProcessedImageProps & ImageCaptureSupport> = ({ setImage, processingError, isProcessing }) => {
    const classes = useStyles()

    const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
    const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo>()

    const webcamRef = useRef<Webcam>(null)

    const capture = useCallback(() => {
        if (webcamRef.current) {
            const deviceImage = webcamRef.current.getScreenshot()
            setImage(deviceImage)
        }
    }, [webcamRef])

    const handleDevices = useCallback((mediaDevices: MediaDeviceInfo[]) => setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')), [setDevices])

    const handleDeviceChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
        const matchedDevice = devices.find((item) => item.deviceId === event.target.value)
        if (matchedDevice) {
            setSelectedDevice(matchedDevice)
        }
    }

    const setupDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices()
        if (devices && devices.length > 0) {
            handleDevices(devices)
        }
    }

    useEffect(() => {
        setupDevices()
    }, [handleDevices])

    useEffect(() => {
        if (devices.length > 0) {
            setSelectedDevice(devices[0])
        }
    }, [devices])

    return (
        <div>
            {devices.length > 1 && (
                <FormControl variant="outlined" className={classes.cameraSelect}>
                    <InputLabel htmlFor="cameraSelect">Select Camera</InputLabel>
                    <Select
                        native
                        value={selectedDevice?.deviceId}
                        onChange={handleDeviceChange}
                        label="Select Camera"
                        inputProps={{
                            id: 'cameraSelect',
                        }}
                        data-testid={TestId.DeviceSelect}
                    >
                        {devices.map((device) => (
                            <option key={device.label} value={device.deviceId}>
                                {device.label}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            )}
            {selectedDevice && (
                <Grid container>
                    <Grid item xs={12}>
                        <Backdrop className={classes.backdrop} open={isProcessing}>
                            <Grid container direction="column">
                                <Grid item>
                                    <div>Photo Processing ...</div>
                                </Grid>
                                <Grid item>
                                    <CircularProgress color="inherit" />
                                </Grid>
                            </Grid>
                        </Backdrop>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={{ deviceId: selectedDevice?.deviceId }}
                            minScreenshotWidth={1280}
                            minScreenshotHeight={720}
                            screenshotQuality={1}
                            width="100%"
                        />
                    </Grid>
                    {processingError.length > 0 && (
                        <Grid item xs={12}>
                            <div className={classes.error}>{processingError}</div>
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.takePhotoButton}>
                        <Button variant="contained" color="primary" onClick={capture} startIcon={<PhotoCamera />} data-testid={TestId.TakePhotoButton}>
                            Take Photo
                        </Button>
                    </Grid>
                </Grid>
            )}
        </div>
    )
}
