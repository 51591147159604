import { IntelSingletonHttpClient } from '@intel-sms/core-services'
import { Endpoints } from './Endpoints'
import { ICountryCodes, IPasscodeEmailRequest, IPasscodeIsValidRequest, IPasscodeIsValidResponse, IPasscodeSmsRequest } from '../interfaces/IPasscode'

export const passcodeEmail = async (payload: IPasscodeEmailRequest): Promise<string> => {
    return await IntelSingletonHttpClient.postData(Endpoints.passcodeEmailEndpoint, payload)
}

export const passcodeSms = async (payload: IPasscodeSmsRequest): Promise<string> => {
    return await IntelSingletonHttpClient.postData(Endpoints.passcodeSmsEndpoint, payload)
}

export const passcodeIsValid = async (payload: IPasscodeIsValidRequest): Promise<IPasscodeIsValidResponse> => {
    const validCheckResp = await IntelSingletonHttpClient.postData<IPasscodeIsValidResponse>(Endpoints.passcodeIsValidEndpoint, payload)
    if (validCheckResp?.isValid == true) {
        return validCheckResp
    }
    throw Error(`${payload.passCode} was not valid`)
}

export const getCountryCodes = async (): Promise<ICountryCodes> => {
    return await IntelSingletonHttpClient.getData(Endpoints.smsCountryCodesEndpoint)
}
