import React, { Component, FC } from 'react'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Paper, { PaperProps } from '@material-ui/core/Paper'
import { keyValuePairs } from '@intel-sms/core-services'
import CustomButton, { ColorTypes } from './button/CustomButton'

function PaperComponent(props: PaperProps) {
    return <Paper {...props} />
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(3),
            backgroundColor: '#E9E9E9',
        },
        title: {
            marginBottom: '0',
            fontFamily: "'IntelOneMd', 'IntelClear', 'Roboto', 'Helvetica', 'Ariel', sans-serif",
            fontWeight: 200,
            fontSize: 24,
            lineHeight: '31px',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    })

export interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string
    children: React.ReactNode
    onClose: () => void
}

export interface ICommand {
    color: ColorTypes
    autoFocus: boolean
    label: string
    canExecute: boolean
    execute: (data: keyValuePairs) => void
    disabled?: boolean
    hidden?: boolean
    component?: Component
}

export interface ActionModalProps {
    children: React.ReactNode
    title: string
    maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
    isOpen: boolean
    handleClose: () => void
    actions: ICommand[]
    testId?: string
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other} style={{ cursor: 'move' }}>
            <Typography variant="h2" className={classes.title}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} data-testid="closeBtn">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(3),
    },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        justifyContent: 'flex-start',
        backgroundColor: 'rgba(0,0,0,0.9)',
    },
}))(MuiDialogActions)

const ActionModal: FC<ActionModalProps> = (props) => {
    //export default function ActionModal(props: ActionModalProps) {
    const { children, title, maxWidth, isOpen, actions, handleClose, testId } = props

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={maxWidth}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
                open={isOpen}
                data-testid={testId ? `${testId}ModalWrapper` : 'modalWrapper'}
                PaperComponent={PaperComponent}
            >
                <DialogTitle id="draggable-dialog-title" onClose={handleClose}>
                    {title}
                </DialogTitle>
                <DialogContent dividers data-testid={testId ? `${testId}ModalContent` : 'modalContent'}>
                    {children}
                </DialogContent>
                <DialogActions>
                    {actions
                        .filter((i) => !i.hidden)
                        .map((item) => {
                            return (
                                item.component ?? (
                                    <CustomButton
                                        key={item.label.replace(/\s/g, '')}
                                        autoFocus={item.autoFocus}
                                        color={item.color}
                                        variant="contained"
                                        disabled={!item.canExecute || item.disabled}
                                        onClick={item.execute}
                                        data-testid={`${item.label.replace(/ /g, '')}Input`}
                                    >
                                        {item.label}
                                    </CustomButton>
                                )
                            )
                        })}
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default ActionModal
