import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'

import { ILoginRequest } from 'interfaces/ILoginRequest'
import { loginWithWwIdLastName } from '../services/UserService'
import { IUser } from '../interfaces/IUser'

import useStyles from '../styles/useStyles'
import { AuthType, ErrorMessage, ApplicationName } from '../constants/Constants'
import { AppContext } from '../AppContext'
import { TestId } from '../constants/testIds'
import { useConfig } from 'hooks/useConfig'

interface IProps {
    onAuthenticated: (isAzureAuth: boolean, user?: IUser | null) => void
}

export const LoginComponent: FC<IProps> = ({ onAuthenticated }) => {
    const [wwId, setWwId] = useState<string>('0')
    const [lastName, setLastName] = useState<string>('')
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const [loginError, setLoginError] = useState<string>('')
    const { updateAuthentication, isPerformanceAuth } = useContext(AppContext)

    const configData = useConfig()
    const [siteKey, setSiteKey] = useState<string>('')
    const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null)
    const recaptchaDisabled = configData['rd'] === 'True'
    const showRecaptcha = !recaptchaDisabled && siteKey.length > 0 && !isPerformanceAuth

    useEffect(() => {
        if (configData['rsk']) {
            setSiteKey(configData['rsk'])
        }
    }, [configData])

    const classes = useStyles()

    const recaptchaChanged = (value: string | null) => {
        setRecaptchaValue(value)
    }

    const handleWwIdLogin = async () => {
        if (lastName.trim().length > 0 && wwId.trim().length > 0 && Number(wwId) > 0) {
            setIsProcessing(true)
            const data: ILoginRequest = { wwId, lastName, token: recaptchaValue }
            try {
                const currentUser: IUser = await loginWithWwIdLastName(data, isPerformanceAuth)
                currentUser.photoIntakeAuthenticationTypeID = AuthType.WwId.value
                currentUser.photoIntakeAuthenticationTypeName = AuthType.WwId.name
                const user = updateAuthentication(currentUser, null)
                onAuthenticated(false, user)
            } catch (error) {
                setLoginError(`${ErrorMessage.LoginError} ${error}`)
            } finally {
                setIsProcessing(false)
            }
        } else {
            setLoginError('WWID and Last Name are required.')
        }
    }

    const keyDownHandler = (event: { key: string; preventDefault: () => void }) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleWwIdLogin()
        }
    }

    return (
        <>
            <Grid container justify="center">
                <Grid item xs={12}>
                    <div className={[classes.title, classes.homeTitle, classes.homePadding].join(' ')}>
                        <h1>{ApplicationName}</h1>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                        <p>Enter Your WWID and Last Name</p>
                    </div>
                </Grid>
                <Grid item xs={11} sm={4} lg={2}>
                    <TextField
                        required
                        fullWidth
                        placeholder="WWID"
                        variant="outlined"
                        margin="dense"
                        type="number"
                        InputProps={{
                            classes: {
                                root: classes.loginInput,
                            },
                            inputMode: 'numeric',
                        }}
                        inputProps={{ 'data-testid': TestId.LoginWwIdInput }}
                        onChange={(e) => setWwId(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item xs={11} sm={4} lg={2}>
                    <TextField
                        required
                        fullWidth
                        placeholder="Last Name"
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                            classes: {
                                root: classes.loginInput,
                            },
                        }}
                        inputProps={{ 'data-testid': TestId.LoginLastNameInput }}
                        onChange={(e) => setLastName(e.target.value)}
                        onKeyDown={keyDownHandler}
                    />
                </Grid>
            </Grid>
            {showRecaptcha && (
                <Grid container justify="center">
                    {console.log('Rendering recaptcha')}
                    <Grid item xs={11} sm={4} lg={2}>
                        <ReCAPTCHA sitekey={siteKey} onChange={recaptchaChanged} />
                    </Grid>
                </Grid>
            )}
            <Grid container justify="center">
                <Grid item xs={11} sm={4} lg={2}>
                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.loginButton}
                        onClick={handleWwIdLogin}
                        disabled={isProcessing}
                        data-testid={TestId.SignInButton}
                    >
                        Sign In
                    </Button>
                </Grid>
            </Grid>
            <Grid container justify="center">
                <Grid item>
                    {loginError && (
                        <Typography className={classes.error} data-testid={TestId.LoginError}>
                            {loginError} Please try again.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
