import React, { FC } from 'react'

import { Button, Grid } from '@material-ui/core'

import instructionsImage from '../images/BadgeInstructions.png'
import successCheckmark from '../images/SuccessCheckmark.svg'
import useStyles from '../styles/useStyles'
import { TestId } from '../constants/testIds'

interface IProps {
    handleContinue: () => void
}

export const BadgePhotoInstructions: FC<IProps> = ({ handleContinue }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.title}>Use the following guidelines for image capture:</div>
            <Grid container justify="center" spacing={0} data-testid={TestId.BadgePhotoInstructionsGrid}>
                <Grid item xs={12} sm={4} style={{ textAlign: 'center' }}>
                    <img src={instructionsImage} alt=" " />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Grid container spacing={0}>
                        <Grid item xs={1} className={classes.takePhotoInstructionsIconGroup}>
                            <img src={successCheckmark} alt=" " className={classes.takePhotoInstructionsIcon} />
                        </Grid>
                        <Grid item xs={11} className={classes.takePhotoInstructionsTextGroup}>
                            <div className={classes.takePhotoInstructionsText}>
                                Image must have at least <b>6 inches</b> above head.
                            </div>
                        </Grid>
                        <Grid item xs={1} className={classes.takePhotoInstructionsIconGroup}>
                            <img src={successCheckmark} alt=" " className={classes.takePhotoInstructionsIcon} />
                        </Grid>
                        <Grid item xs={11} className={classes.takePhotoInstructionsTextGroup}>
                            <div className={classes.takePhotoInstructionsText}>
                                The worker being photographed must be facing the <b>camera straight on</b> (the body cannot be turned to the side, with face
                                forward directly at the camera).
                            </div>
                        </Grid>
                        <Grid item xs={1} className={classes.takePhotoInstructionsIconGroup}>
                            <img src={successCheckmark} alt=" " className={classes.takePhotoInstructionsIcon} />
                        </Grid>
                        <Grid item xs={11} className={classes.takePhotoInstructionsTextGroup}>
                            <div className={classes.takePhotoInstructionsText}>
                                Wear a shirt or head covering that is different color as the background where you take the picture.
                            </div>
                        </Grid>
                        <Grid item xs={1} className={classes.takePhotoInstructionsIconGroup}>
                            <img src={successCheckmark} alt=" " className={classes.takePhotoInstructionsIcon} />
                        </Grid>
                        <Grid item xs={11} className={classes.takePhotoInstructionsTextGroup}>
                            <div className={classes.takePhotoInstructionsText}>
                                Ensure there are <b>no other objects</b> in the view other than the person being photographed.
                            </div>
                        </Grid>
                        <Grid item xs={1} className={classes.takePhotoInstructionsIconGroup}>
                            <img src={successCheckmark} alt=" " className={classes.takePhotoInstructionsIcon} />
                        </Grid>
                        <Grid item xs={11} className={classes.takePhotoInstructionsTextGroup}>
                            <div className={classes.takePhotoInstructionsText}>Image must include the mid torso for cropping purposes.</div>
                        </Grid>
                        <Grid item xs={12} className={classes.takePhotoInstructionsButton}>
                            <Button variant="contained" color="primary" data-testid={TestId.BadgePhotoInstructionsContinueButton} onClick={handleContinue}>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
