import React, { FC } from 'react'
import { Container, Button, Grid } from '@material-ui/core'

import { ShippingInfoComponent } from './ShippingInfoComponent'
import { ShippingNotificationComponent } from './ShippingNotificationComponent'
import { RequestType } from 'constants/Constants'
import { TestId } from '../constants/testIds'

interface IProps {
    handleCancel: () => void
}

export const Notification: FC<IProps> = ({ handleCancel }) => {
    return (
        <Container maxWidth="sm" data-testid={TestId.NotificationContainer}>
            <Grid item xs={12}>
                <ShippingInfoComponent requestType={RequestType.ReplaceExistingPhoto.value} />
            </Grid>
            <Grid item xs={12}>
                <ShippingNotificationComponent />
            </Grid>
            <hr />
            <Grid container>
                <Grid item xs={3} sm={2}>
                    <Button variant="contained" color="primary" type="submit" data-testid={TestId.NotificationSubmitButton}>
                        Submit
                    </Button>
                </Grid>
                <Grid item xs={3} sm={2}>
                    <Button color="primary" onClick={handleCancel} data-testid={TestId.NotificationCancelButton}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
