import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import { StepsComponent } from '../components/StepsComponent'
import { Notification } from '../components/Notification'
import { BadgePhoto } from '../components/BadgePhoto'
import { ReplaceExistingPhotoSummary } from '../components/ReplaceExistingPhotoSummary'

import { RequestType, ShippingNotificationOption, StepDescription } from '../constants/Constants'
import { FCWithAuth, FlowWithPayload } from '../types/CommonTypes'
import { ROUTE_PATHS } from '../constants/Routes'
import useStyles from '../styles/useStyles'
import { createReplaceExistingPhotoRequest } from '../services/ImageService'
import { IReplaceExistingPhotoRequest } from '../interfaces/IReplaceExistingPhotoRequest'
import { AppContext } from '../AppContext'
import { ReplaceExistingPhotoType } from '../types/ReplaceExistingPhotoType'
import { CompletedStepTypes } from '../types/CompletedStepTypes'
import { NotificationValues } from 'types/NotificationValuesTypes'
import { useSnackbar } from 'notistack'

export const ReplaceExistingPhoto: FCWithAuth = () => {
    const { user } = useContext(AppContext)
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar()

    const initialValues: ReplaceExistingPhotoType = {
        notificationValues: {
            notificationOption: JSON.stringify(ShippingNotificationOption.Email),
            email: user?.email ?? '',
            smsNumber: '',
        },
        setNotificationValues: () => {},
        badgePhoto: '',
        setBadgePhoto: () => {},
        completedSteps: {
            notificationValues: false,
            badgeImage: false,
        },
        setCompletedSteps: () => {},
    }

    const [notificationValues, setNotificationValues] = useState<NotificationValues>(initialValues.notificationValues)
    const [badgePhoto, setBadgePhoto] = useState<string>(initialValues.badgePhoto)
    const [completedSteps, setCompletedSteps] = useState<CompletedStepTypes>(initialValues.completedSteps)

    const stepCount = 3

    const formMethods = useForm<NotificationValues>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: notificationValues,
    })

    const [currentStep, setCurrentStep] = useState<number>(1)
    const [previousStep, setPreviousStep] = useState<number>(0)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)
    const history = useHistory()

    const onNotificationSubmit: SubmitHandler<NotificationValues> = (data) => {
        if (validateNotification()) {
            setNotificationValues(data)
            handleContinue()
        }
    }

    const validateNotification = (): boolean => {
        let isValid = true

        if (formMethods.getValues('notificationOption') === JSON.stringify(ShippingNotificationOption.Email)) {
            if (formMethods.getValues('email').trim().length === 0) {
                formMethods.setError('email', { type: 'manual', message: 'Required' })
                isValid = false
            }
        } else {
            if (formMethods.getValues('smsNumber').trim().length === 0) {
                formMethods.setError('smsNumber', { type: 'manual', message: 'Required' })
                isValid = false
            }
        }

        return isValid
    }

    const getStepDescription = (step: number): string => {
        switch (step) {
            case 1:
                return StepDescription.NotificationInformation
            case 2:
                return StepDescription.TakeNewPhoto
            case 3:
                return StepDescription.Summary
            default:
                return ''
        }
    }

    const handleCancel = () => {
        if (previousStep > 0) {
            setCurrentStep(previousStep)
            setPreviousStep(0)
        } else {
            history.push(ROUTE_PATHS.WORKFLOW_OPTIONS)
        }
    }

    const handleContinue = () => {
        if (previousStep > 0) {
            setCurrentStep(previousStep)
            setPreviousStep(0)
        } else {
            setStepComplete(currentStep)
            setCurrentStep(currentStep + 1)
        }
    }

    const handleBadgePhotoContinue = (image: string | null) => {
        if (image) {
            setBadgePhoto(image)
            handleContinue()
        }
    }

    const handleFinishAndSend = async () => {
        setIsProcessing(true)
        if (user) {
            const payload: IReplaceExistingPhotoRequest = {
                wwId: user?.wwId,
                photoIntakeAuthenticationTypeID: user.photoIntakeAuthenticationTypeID,
                photoIntakeAuthenticationTypeName: user.photoIntakeAuthenticationTypeName,
                photoIntakeRequestTypeID: RequestType.ReplaceExistingPhoto.value,
                photoIntakeRequestTypeName: RequestType.ReplaceExistingPhoto.name,
                badgePhoto: badgePhoto,
                firstName: user.firstName,
                lastName: user.lastName,
                photoIntakeNotificationTypeID: JSON.parse(notificationValues.notificationOption).value,
                photoIntakeNotificationTypeName: JSON.parse(notificationValues.notificationOption).name,
                email: notificationValues.email,
                smsNumber: notificationValues.smsNumber,
                campusName: user.campus ?? '',
                mailStop: user.mailStop ?? '',
            }
            try {
                const response = await createReplaceExistingPhotoRequest(payload)
                if (response.startsWith('ERROR - ')) {
                    alert('handleFinishAndSend - API error')
                } else {
                    const payload: FlowWithPayload<NotificationValues> = { flow: RequestType.ReplaceExistingBadge.name, payload: notificationValues }
                    history.push({ pathname: ROUTE_PATHS.PHOTO_SENT, state: payload })
                }
            } catch (error) {
                enqueueSnackbar('Image is not valid. Please try uploading a new jpg, png or gif file.', { variant: 'error' })
                console.log('handleFinishAndSend-error', error)
            } finally {
                setIsProcessing(false)
            }
        }
    }

    const handleEditNotificationInfo = async () => {
        setCurrentStep(1)
        setPreviousStep(stepCount)
    }

    const handleEditBadgePhoto = async () => {
        setCurrentStep(stepCount - 1)
        setPreviousStep(stepCount)
    }

    const validateStepSelection = (requestedStep: number) => {
        if (requestedStep === 1) {
            return true
        }

        const stepDescription = getStepDescription(requestedStep)

        if (stepDescription === StepDescription.TakeNewPhoto) {
            // Make sure notification info is complete
            if (completedSteps.notificationValues) {
                return true
            }
        }

        if (stepDescription === StepDescription.Summary) {
            // Make sure badge image has been captured
            if (completedSteps.badgeImage) {
                return true
            }
        }

        return false
    }

    const setStepComplete = (step: number) => {
        let steps: CompletedStepTypes
        const stepDescription = getStepDescription(step)
        switch (stepDescription) {
            case StepDescription.NotificationInformation:
                steps = { notificationValues: true, badgeImage: completedSteps.badgeImage }
                break

            case StepDescription.TakeNewPhoto:
                steps = { notificationValues: true, badgeImage: true }
                break

            default:
                // Shouldn't be able to get here
                steps = { notificationValues: false, badgeImage: false }
                break
        }

        setCompletedSteps(steps)
    }

    return (
        <div className={classes.root}>
            <StepsComponent
                stepCount={stepCount}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                getStepDescription={getStepDescription}
                validateStepSelection={validateStepSelection}
                validateShipping={validateNotification}
            />
            {getStepDescription(currentStep) === StepDescription.NotificationInformation && (
                <FormProvider {...formMethods}>
                    <form onSubmit={formMethods.handleSubmit(onNotificationSubmit)}>
                        <Notification handleCancel={handleCancel} />
                    </form>
                </FormProvider>
            )}
            {getStepDescription(currentStep) === StepDescription.TakeNewPhoto && <BadgePhoto handleViewPhotoContinue={handleBadgePhotoContinue} />}
            {getStepDescription(currentStep) === StepDescription.Summary && (
                <ReplaceExistingPhotoSummary
                    isProcessing={isProcessing}
                    notificationValues={notificationValues}
                    badgePhoto={badgePhoto}
                    handleFinishAndSend={handleFinishAndSend}
                    handleEditShippingInfo={handleEditNotificationInfo}
                    handleEditBadgePhoto={handleEditBadgePhoto}
                />
            )}
        </div>
    )
}

ReplaceExistingPhoto.requiresAuth = true
