import { ChangeEvent, useState } from 'react'
import { FilledTextFieldProps, InputAdornment, OutlinedTextFieldProps, StandardTextFieldProps, TextField, Typography } from '@material-ui/core'

interface CustomStandardTextFieldProps extends StandardTextFieldProps {
    isEnglishOnly?: boolean
}

interface CustomFilledTextFieldProps extends FilledTextFieldProps {
    isEnglishOnly?: boolean
}

interface CustomOutlinedTextFieldProps extends OutlinedTextFieldProps {
    isEnglishOnly?: boolean
}

type CustomTextFieldProps = CustomStandardTextFieldProps | CustomFilledTextFieldProps | CustomOutlinedTextFieldProps

export default function TextFieldWithCharacterCount(props: CustomTextFieldProps) {
    const value = props.value as string | number
    const { inputProps } = props
    const length = value ? value.toString().length : 0
    const [counterState, setCounterState] = useState({ color: 'grey', length })
    const maxLength = inputProps?.maxLength
    const InputProps = props.InputProps || {}
    const { endAdornment } = InputProps
    const [error, setError] = useState(false)

    const englishOnlyRegex = /^[a-zA-Z\s-]*$/

    if (endAdornment || !maxLength) {
        return <TextField {...props} />
    }
    const { onChange } = props
    InputProps.endAdornment = (
        <InputAdornment position="end">
            <Typography variant="body2" style={{ color: counterState.color }}>
                {counterState.length} / {maxLength}
            </Typography>
        </InputAdornment>
    )
    const updateCounterState = (withValue: string) => {
        if (withValue.length === maxLength) {
            setCounterState({
                color: 'red',
                length: withValue.length,
            })
        } else {
            setCounterState({
                color: 'grey',
                length: withValue.length,
            })
        }
    }
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const userInput = event.target.value
        const lengthRestrictedString = userInput.substring(0, maxLength)
        updateCounterState(lengthRestrictedString)
        if (userInput.length <= maxLength && onChange) {
            if (props.isEnglishOnly) {
                if (englishOnlyRegex.test(event.target.value)) {
                    onChange(event)
                    setError(false)
                } else {
                    setError(true)
                }
            } else {
                onChange(event)
            }
        }
    }

    return <TextField {...props} onChange={onChangeHandler} InputProps={InputProps} helperText={error ? 'The character entered is not allowed.' : undefined} />
}
