import React, { useContext, useState } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { ApplicationName, ShippingNotificationOption, TwoAuthVerificationText } from 'constants/Constants'
import { useHistory } from 'react-router'
import { ROUTE_PATHS } from 'constants/Routes'
import { PasscodeValues } from 'types/PasscodeTypes'
import { useLocation } from 'react-router-dom'
import { handlePasscodeSubmission } from 'utils/PasscodeHandlers'
import { parsePhoneNumber } from 'libphonenumber-js'
import { passcodeIsValid } from 'services/PasscodeService'
import { useSnackbar } from 'notistack'
import { AppContext } from 'AppContext'
import { IUser } from 'interfaces/IUser'
import { FCWithAuth } from 'types/CommonTypes'

export const CodeEntry: FCWithAuth = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar()
    const passcodeData = location.state as PasscodeValues
    const [code, setCode] = useState<string>('')
    const { updateAuthentication, user } = useContext(AppContext)

    const handleCodeCheck = async () => {
        await passcodeIsValid({ wwid: passcodeData.wwid, passCode: code })
            .then((validateResponse) => {
                updateAuthentication({ ...user, token: validateResponse.token } as IUser, null)
                history.push(ROUTE_PATHS.PRIVACY)
            })
            .catch((e) => enqueueSnackbar(`Invalid Code: ${e.message}`, { variant: 'error' }))
    }

    return (
        <div className={classes.loginRoot}>
            <Grid container justify="center">
                <Grid item xs={8}>
                    <div className={[classes.title, classes.homeTitle, classes.homePadding].join(' ')}>
                        <h1>{ApplicationName}</h1>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                        <p>
                            {TwoAuthVerificationText.CodeSent}{' '}
                            {passcodeData?.passcodeOption === ShippingNotificationOption.Sms.name
                                ? parsePhoneNumber(passcodeData?.smsNumber).formatNational()
                                : passcodeData?.email}
                        </p>
                        <p>{TwoAuthVerificationText.EnterCode}</p>
                    </div>
                    <div style={{ textAlign: 'center' }} className={classes.textInput}>
                        <TextField placeholder="Code" variant="outlined" size="small" onChange={(e) => setCode(e.target.value)} />
                        &nbsp; &nbsp; &nbsp;
                        <Button type="submit" onClick={handleCodeCheck} variant="contained" color="default">
                            Submit
                        </Button>
                    </div>
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                        <p>{TwoAuthVerificationText.ReceivceCode}</p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ textAlign: 'center' }}>
                        <p>{TwoAuthVerificationText.DidNotReceiveCode}</p>
                    </div>
                </Grid>
                <Button
                    onClick={() => handlePasscodeSubmission(passcodeData, true, history, enqueueSnackbar)}
                    variant="contained"
                    color="default"
                    type="submit"
                >
                    Resend Code
                </Button>
            </Grid>
        </div>
    )
}

CodeEntry.requiresAuth = true
