import React, { FC, useEffect, useState } from 'react'
import { Container, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { BadgePhotoStep, ViewPhotoText } from '../constants/Constants'
import { TakePhotoComponent } from './TakePhotoComponent'
import { AttachPhotoComponent } from './AttachPhotoComponent'
import { ViewPhotoComponent } from './ViewPhotoComponent'
import { BadgePhotoInstructions } from './BadgePhotoInstructions'
import { useImageProcessing } from 'hooks/useImageProcessing'
import RemotelyProcessedImageWrapper from 'components/photo/RemoteProcessedPhoto'

interface IProps {
    handleViewPhotoContinue: (image: string | null) => void
}

export const BadgePhoto: FC<IProps> = ({ handleViewPhotoContinue }) => {
    const classes = useStyles()
    const [captureOption, setCaptureOption] = useState(BadgePhotoStep.Instructions)
    const { isProcessing, processingError, invalidImageReason, capturedImage, attachLocalFile, setImage, retakePhoto } = useImageProcessing()

    const showViewComponent = capturedImage || invalidImageReason
    useEffect(() => {
        if (captureOption != BadgePhotoStep.Instructions && capturedImage && capturedImage.length > 0) {
            setCaptureOption(BadgePhotoStep.ViewPhoto)
        } else if (captureOption != BadgePhotoStep.Instructions) {
            setCaptureOption(BadgePhotoStep.TakePhoto)
        }
    }, [capturedImage])

    const handleInstructionsContinue = () => {
        setCaptureOption(BadgePhotoStep.TakePhoto)
    }

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCaptureOption((event.target as HTMLInputElement).value)
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                {captureOption === BadgePhotoStep.Instructions && <BadgePhotoInstructions handleContinue={handleInstructionsContinue} />}

                {captureOption !== BadgePhotoStep.Instructions && !showViewComponent && (
                    <Grid item xs={12} className={classes.photoChoice}>
                        <Grid container className={classes.textInput}>
                            <Grid item xs={12}>
                                <div className={classes.title}>
                                    <h1>Capture Photo</h1>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <RadioGroup onChange={handleOptionChange}>
                                <FormControlLabel
                                    name="takephoto"
                                    value={BadgePhotoStep.TakePhoto}
                                    control={<Radio color="primary" checked={captureOption === BadgePhotoStep.TakePhoto} />}
                                    label="Take Photo"
                                />
                                <FormControlLabel
                                    name="attachimage"
                                    value={BadgePhotoStep.AttachImage}
                                    control={<Radio color="primary" checked={captureOption === BadgePhotoStep.AttachImage} />}
                                    label="Attach Image"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                )}
                {captureOption === BadgePhotoStep.AttachImage && !showViewComponent && (
                    <Container maxWidth="sm">
                        <Container className={classes.attachPhotoContainer}>
                            <RemotelyProcessedImageWrapper processingError={processingError} isProcessing={isProcessing}>
                                <AttachPhotoComponent attachLocalFile={attachLocalFile} />
                            </RemotelyProcessedImageWrapper>
                        </Container>
                    </Container>
                )}
                {captureOption === BadgePhotoStep.TakePhoto && !showViewComponent && (
                    <Container maxWidth="sm">
                        <Container className={classes.takePhotoCameraContainer}>
                            <TakePhotoComponent setImage={setImage} processingError={processingError} isProcessing={isProcessing} />
                        </Container>
                    </Container>
                )}
                {showViewComponent && (
                    <ViewPhotoComponent
                        capturedImage={capturedImage}
                        inValidImageReason={invalidImageReason}
                        validImageText={ViewPhotoText.BadgeId}
                        handleContinue={handleViewPhotoContinue}
                        handleRetakePhoto={retakePhoto}
                    />
                )}
            </Container>
        </div>
    )
}
