import { IntelSingletonHttpClient } from '@intel-sms/core-services'
import { Endpoints } from './Endpoints'

let version = 'No Version'

export const getAppVersion = async (): Promise<string> => {
    if (version !== 'No Version') {
        return version
    }
    const remoteVersion = await IntelSingletonHttpClient.getData<string>(Endpoints.versionEndpoint)
    version = remoteVersion
    return version
}
