import React, { FC, useContext } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import { RequiresAuthType } from 'types/CommonTypes'
import { AppContext } from './AppContext'
import { ROUTE_PATHS } from './constants/Routes'
import { AuthType } from 'constants/Constants'

type IProps = Omit<RouteProps, 'component'> & {
    component: RouteProps['component'] & RequiresAuthType
}

export const AuthenticatedRoute: FC<IProps> = ({ component, ...props }) => {
    const { isAuthenticated, user } = useContext(AppContext)
    const { requiresAuth, requiresAzure } = component

    if (!isAuthenticated() && requiresAuth) {
        return <Redirect to={ROUTE_PATHS.HOME} />
    }

    if (isAuthenticated() && requiresAzure && user?.photoIntakeAuthenticationTypeID === AuthType.WwId.value) {
        return <Redirect to={ROUTE_PATHS.WORKFLOW_OPTIONS} />
    }

    return <Route {...props} component={component} />
}
