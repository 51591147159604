import React, { FC, useEffect, useState } from 'react'
import { Grid, GridSize, Typography } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { TestId } from '../constants/testIds'
import { getAppVersion } from '../services/VerificationService'
import { Footer as Constants } from '../constants/Constants'
import { useConfig } from 'hooks/useConfig'

interface FooterProps {
    showLinks?: boolean
}

export const Footer: FC<FooterProps> = ({ showLinks = false }) => {
    const classes = useStyles()
    const [version, setVersion] = useState<string>('')
    const { faq, privacy } = useConfig()

    useEffect(() => {
        const fetchVersion = async () => {
            setVersion(await getAppVersion())
        }
        fetchVersion()
    }, [])

    const showPrivacy = !!privacy
    const showFaq = !!faq
    let gridItemsize: GridSize = 6
    if (showLinks) {
        if (showFaq && showPrivacy) {
            gridItemsize = 3
        } else if (showFaq || showPrivacy) {
            gridItemsize = 4
        }
    }
    return (
        <div className={classes.footer} data-testid={TestId.Footer}>
            <Grid container justify="flex-start">
                <Grid item xs={gridItemsize} sm={gridItemsize} md={gridItemsize} data-testid="intelConfidentialWrapper">
                    <Typography component={'div'} className={classes.copyrightLegalNav}>
                        {Constants.Confidential}
                    </Typography>
                </Grid>
                {showLinks && showFaq && (
                    <Grid item xs={gridItemsize} sm={gridItemsize} md={gridItemsize} className={classes.centerWrapper} data-testid="FaqWrapper">
                        <a title="FAQ" href={faq as string} target="_blank">
                            FAQ
                        </a>
                    </Grid>
                )}
                {showLinks && showPrivacy && (
                    <Grid item xs={gridItemsize} sm={gridItemsize} md={gridItemsize} className={classes.centerWrapper} data-testid="PrivacyWrapper">
                        <a title="Privacy" href={privacy as string} target="_blank">
                            Privacy
                        </a>
                    </Grid>
                )}
                <Grid item xs={gridItemsize} sm={gridItemsize} md={gridItemsize} className={classes.copyrightWrapper} data-testid="versionWrapper">
                    <Typography component={'div'} className={classes.copyrightLegalNav}>
                        {Constants.Version}: {version}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
