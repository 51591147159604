import { PublicClientApplication, AuthenticationResult, AccountInfo, EndSessionRequest, BrowserAuthError } from '@azure/msal-browser'
import { AuthType } from 'constants/Constants'
import { getConfig } from 'services/ConfigService'
import { loginWithAzure } from 'services/UserService'
import { getMsalConfig, loginRequest } from '../authConfig'
import { IUser } from '../interfaces/IUser'

export class AzureAuthenticationContext {
    private myMsalObj: PublicClientApplication | null = null

    async getClient(): Promise<PublicClientApplication> {
        if (this.myMsalObj) {
            return this.myMsalObj
        }
        const configurations = await getConfig()
        const msalConfig = getMsalConfig(configurations['c'], configurations['t'])
        this.myMsalObj = new PublicClientApplication(msalConfig)
        return this.myMsalObj
    }

    async handleAuthResult(authResult?: AuthenticationResult | null): Promise<[user: IUser | null, account: AccountInfo | null]> {
        if (authResult) {
            const refreshedUser = await loginWithAzure(authResult.idToken)
            refreshedUser.photoIntakeAuthenticationTypeID = AuthType.AzureAd.value
            refreshedUser.photoIntakeAuthenticationTypeName = AuthType.AzureAd.name
            return [refreshedUser, authResult.account]
        }
        return [null, null]
    }

    async login(): Promise<AuthenticationResult | undefined> {
        try {
            const client = await this.getClient()
            const resp: AuthenticationResult = await client.loginPopup(loginRequest)
            return resp
        } catch (error) {
            if (
                !(error instanceof BrowserAuthError) ||
                (error.errorMessage !== 'User cancelled the flow.' && !error.errorMessage.startsWith('Interaction is currently in progress.'))
            ) {
                throw error
            }
        }
    }

    async logout(account: AccountInfo): Promise<void> {
        const logOutRequest: EndSessionRequest = {
            account,
        }
        const client = await this.getClient()
        await client.logoutPopup(logOutRequest)
    }
}

const azureAuthSingleton = new AzureAuthenticationContext()
export default azureAuthSingleton
