import { Dispatch, FC, useEffect, useState } from 'react'
import { FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { LoginNotificationOption } from '../constants/Constants'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { TestId } from 'constants/testIds'
import { getCountryCodes } from 'services/PasscodeService'
import { CountryData, ICountryCodes } from '../interfaces/IPasscode'
import { PasscodeValues } from 'types/PasscodeTypes'
import { theme } from 'styles/theme'

interface IProps {
    passcodeData: PasscodeValues
    setPasscodeData: Dispatch<PasscodeValues>
}

export const LoginCodeComponent: FC<IProps> = ({ passcodeData, setPasscodeData }) => {
    const classes = useStyles()
    const [countryCodes, setCountryCodes] = useState<string[]>()

    const handlePhoneChange = (value: string, data: CountryData, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
        setPasscodeData({ ...passcodeData, smsNumber: formattedValue })
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value?: string) => {
        setPasscodeData({ ...passcodeData, [event.target.name]: value ?? event.target.value })
    }

    useEffect(() => {
        getCountryCodes().then((result: ICountryCodes) => {
            const codes = result && result.countryCodes.map((item) => item.countryAbbr)
            setCountryCodes(codes)
        })
    }, [])

    return (
        <Grid item xs={12}>
            <div style={{ textAlign: 'center' }}>
                <RadioGroup onChange={handleChange} name="passcodeOption" value={passcodeData.passcodeOption}>
                    <FormControlLabel value={LoginNotificationOption.Email} control={<Radio className={classes.inverse} />} label="Email" />
                    {passcodeData.passcodeOption === LoginNotificationOption.Email && (
                        <Grid container className={classes.textInput}>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={handleChange}
                                    type="email"
                                    fullWidth
                                    required
                                    inputProps={{ required: true }}
                                    size="small"
                                    variant="outlined"
                                    name="email"
                                    value={passcodeData.email}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <FormControlLabel value={LoginNotificationOption.Sms} control={<Radio className={classes.inverse} />} label="Text SMS" />
                    {passcodeData.passcodeOption === LoginNotificationOption.Sms && (
                        <Grid container className={classes.textInput}>
                            <Grid item xs={12} style={{ textAlign: 'left', color: theme.palette.common.black }}>
                                <ReactPhoneInput
                                    inputProps={{ 'data-testid': TestId.Sms, required: true }}
                                    country="us"
                                    countryCodeEditable={false}
                                    onlyCountries={countryCodes}
                                    onChange={handlePhoneChange}
                                />
                            </Grid>
                        </Grid>
                    )}
                </RadioGroup>
            </div>
        </Grid>
    )
}
