import React, { FC } from 'react'

import { Backdrop, Button, CircularProgress, Grid, Typography } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { StepDescription, ShippingNotificationOption } from '../constants/Constants'
import { NotificationValues } from '../types/NotificationValuesTypes'
import { TestId } from '../constants/testIds'
import { parsePhoneNumber } from 'libphonenumber-js'

interface IProps {
    isProcessing: boolean
    notificationValues: NotificationValues
    badgePhoto: string
    handleFinishAndSend: () => void
    handleEditShippingInfo: () => void
    handleEditBadgePhoto: () => void
}

export const ReplaceExistingPhotoSummary: FC<IProps> = ({
    isProcessing,
    notificationValues,
    badgePhoto,
    handleFinishAndSend,
    handleEditShippingInfo,
    handleEditBadgePhoto,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.viewPhotoTop}>
            <span className={classes.viewPhotoStatusText}>Summary</span>
            <Grid container justify="center">
                <Grid item xs={12} sm={4}>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.summarySectionTitleText}>
                            {StepDescription.NotificationInformation}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">
                                {notificationValues.notificationOption === JSON.stringify(ShippingNotificationOption.Email) ? 'Email' : 'SMS Number'}
                            </Typography>
                            {notificationValues.notificationOption === JSON.stringify(ShippingNotificationOption.Email)
                                ? notificationValues.email
                                : parsePhoneNumber(`+${notificationValues.smsNumber}`).formatNational()}
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={handleEditShippingInfo} data-testid={TestId.ReplaceExistingPhotoSummaryEditShippingButton}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.summarySectionTitleText}>
                            {StepDescription.BadgePhoto}
                        </Grid>
                        <Grid item xs={12}>
                            <img src={badgePhoto} alt=" " className={classes.summaryImage} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={handleEditBadgePhoto} data-testid={TestId.ReplaceExistingPhotoSummaryEditPhotoButton}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={isProcessing}>
                <Grid container direction="column">
                    <Grid item>
                        <div>Sending ...</div>
                    </Grid>
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Grid>
            </Backdrop>
            <Button
                variant="contained"
                color="primary"
                className={classes.summaryFinishButton}
                disabled={isProcessing}
                onClick={handleFinishAndSend}
                data-testid={TestId.ReplaceExistingPhotoSummaryFinishButton}
            >
                Finish and Send to Intel
            </Button>
        </div>
    )
}
