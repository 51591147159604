import React, { FC } from 'react'

import { Backdrop, Button, CircularProgress, Grid } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { StepDescription, ShippingReceiveOption, ShippingNotificationOption } from '../constants/Constants'
import { ShippingValues } from 'types/ShippingValuesTypes'
import { ImageTypes } from 'types/ImageTypes'
import { TestId } from '../constants/testIds'
import { parsePhoneNumber } from 'libphonenumber-js'

interface IProps {
    isProcessing: boolean
    isNew: boolean
    shippingValues: ShippingValues
    images: ImageTypes
    handleFinishAndSend: () => void
    handleEditShippingInfo: () => void
    handleEditGovtPhoto?: () => void
    handleEditBadgePhoto: () => void
}

export const RequestNewBadgeSummary: FC<IProps> = ({
    isProcessing,
    isNew,
    shippingValues,
    images,
    handleFinishAndSend,
    handleEditShippingInfo,
    handleEditGovtPhoto,
    handleEditBadgePhoto,
}) => {
    const classes = useStyles()

    return (
        <div className={classes.viewPhotoTop}>
            <span className={classes.viewPhotoStatusText}>Summary</span>
            <Grid container justify="center">
                <Grid item xs={12} sm={4}>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.summarySectionTitleText}>
                            {StepDescription.ShippingInformation}
                        </Grid>
                        {shippingValues.receiveOption === JSON.stringify(ShippingReceiveOption.Ship) ? (
                            <>
                                <Grid item xs={12}>
                                    {shippingValues.addressOne}
                                </Grid>
                                <Grid item xs={12}>
                                    {shippingValues.addressTwo}
                                </Grid>
                                <Grid item xs={12}>
                                    {shippingValues.city}, {shippingValues.state} {shippingValues.zip}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    {shippingValues.campusName}
                                </Grid>
                                <Grid item xs={12}>
                                    {shippingValues.mailStop}
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} className={classes.summaryNotificationText}>
                            Notification Method
                        </Grid>
                        <Grid item xs={12}>
                            {shippingValues.notificationOption === JSON.stringify(ShippingNotificationOption.Email)
                                ? shippingValues.email
                                : parsePhoneNumber(`+${shippingValues.smsNumber}`).formatNational()}
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={handleEditShippingInfo} data-testid={TestId.RequestNewBadgeSummaryEditShippingButton}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                {isNew && shippingValues.receiveOption === JSON.stringify(ShippingReceiveOption.Ship) && (
                    <Grid item xs={12} sm={4}>
                        <Grid container justify="center">
                            <Grid item xs={12} className={classes.summarySectionTitleText}>
                                {StepDescription.GovernmentId}
                            </Grid>
                            <Grid item xs={12}>
                                <img src={images.governmentId} alt=" " className={classes.summaryImage} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button color="primary" onClick={handleEditGovtPhoto} data-testid={TestId.RequestNewBadgeSummaryEditGovtIdButton}>
                                    Edit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    <Grid container justify="center">
                        <Grid item xs={12} className={classes.summarySectionTitleText}>
                            {StepDescription.BadgePhoto}
                        </Grid>
                        <Grid item xs={12}>
                            <img src={images.badgePhoto} alt=" " className={classes.summaryImage} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button color="primary" onClick={handleEditBadgePhoto} data-testid={TestId.RequestNewBadgeSummaryEditPhotoButton}>
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={isProcessing}>
                <Grid container direction="column">
                    <Grid item>
                        <div>Sending ...</div>
                    </Grid>
                    <Grid item>
                        <CircularProgress color="inherit" />
                    </Grid>
                </Grid>
            </Backdrop>
            <Button
                variant="contained"
                color="primary"
                className={classes.summaryFinishButton}
                disabled={isProcessing}
                onClick={handleFinishAndSend}
                data-testid={TestId.RequestNewBadgeSummaryFinishButton}
            >
                Finish and Send to Intel
            </Button>
        </div>
    )
}
