import React, { createContext, FC, useEffect, useState } from 'react'
import { AccountInfo } from '@azure/msal-browser'
import { TokenManager, UserInfo } from '@intel-sms/core-services'
import AzureModule, { AzureAuthenticationContext } from './azure/AzureAuthenticationContext'
import { IUser } from './interfaces/IUser'
import { useLocation } from 'react-router-dom'

export type AutoLoginData = {
    autoLoginInProgress: boolean
    setAutoLoginInProgress?: React.Dispatch<React.SetStateAction<boolean>>
    loginSuccess: boolean
    setLoginSuccess?: React.Dispatch<React.SetStateAction<boolean>>
    hasAttemptedLogin: boolean
    setHasAttemptedLogin?: React.Dispatch<React.SetStateAction<boolean>>
}

export type AuthenticationContext = {
    authenticationModule: AzureAuthenticationContext
    user: IUser | null
    azureAccountInfo: AccountInfo | null
    isPerformanceAuth: boolean
    isAuthenticated: () => boolean
    updateAuthentication: (currentUser: IUser | null, azureAcctInfo: AccountInfo | null) => IUser | null
    autoLoginData: AutoLoginData
}
const initialValues: AuthenticationContext = {
    authenticationModule: AzureModule,
    user: null,
    azureAccountInfo: null,
    isPerformanceAuth: false,
    isAuthenticated: () => false,
    updateAuthentication: () => {
        return null
    },
    autoLoginData: {
        autoLoginInProgress: true,
        loginSuccess: false,
        hasAttemptedLogin: false,
    },
}

export const AppContext = createContext<AuthenticationContext>(initialValues)

type DecodedToken = UserInfo & { Temporary: string }
export const AppProvider: FC<unknown> = ({ children }) => {
    const [authenticationModule] = useState<AzureAuthenticationContext>(AzureModule)
    const [user, setUser] = useState<IUser | null>(null)
    const [azureAccountInfo, setAzureAccountInfo] = useState<AccountInfo | null>(null)
    const [isPerformanceAuth, setPerformanceAuth] = useState(false)
    const location = useLocation()
    const [autoLoginInProgress, setAutoLoginInProgress] = useState<boolean>(true)
    const [loginSuccess, setLoginSuccess] = useState<boolean>(false)
    const [hasAttemptedLogin, setHasAttemptedLogin] = useState<boolean>(false)

    // Check for the performanceAuth header on first load
    useEffect(() => {
        if (location?.search?.toLowerCase()?.includes('performanceauth=true')) {
            setPerformanceAuth(true)
        }
    }, [])

    const isAuthenticated = (): boolean => {
        return user !== null
    }

    const updateAuthentication = (currentUser: IUser | null, azureAcctInfo: AccountInfo | null): IUser | null => {
        let tokenData: DecodedToken | null = null
        if (currentUser) {
            TokenManager.setToken(currentUser.token)
            tokenData = TokenManager.decodeUserDataFromToken(currentUser.token) as DecodedToken
            setAzureAccountInfo(azureAcctInfo)
        } else {
            TokenManager.setToken(null)
            setAzureAccountInfo(null)
        }
        const updatedUser = currentUser ? { ...currentUser, requiresMFA: tokenData?.Temporary !== 'False' } : null
        setUser(updatedUser)
        return updatedUser
    }

    return (
        <AppContext.Provider
            value={{
                authenticationModule,
                user,
                azureAccountInfo,
                isPerformanceAuth,
                isAuthenticated,
                updateAuthentication,
                autoLoginData: { autoLoginInProgress, setAutoLoginInProgress, loginSuccess, setLoginSuccess, hasAttemptedLogin, setHasAttemptedLogin },
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
