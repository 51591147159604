import React, { FC } from 'react'
import { Box, Grid, Link } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { TestId } from 'constants/testIds'

interface IProps {
    stepCount: number
    currentStep: number
    setCurrentStep: (stepNumber: number) => void
    getStepDescription: (stepNumber: number) => string
    validateStepSelection: (stepNumber: number) => boolean
    validateShipping: () => boolean
}

export const StepsComponent: FC<IProps> = ({ stepCount, currentStep, setCurrentStep, getStepDescription, validateStepSelection, validateShipping }) => {
    const classes = useStyles()

    const getIcon = (step: number): string => {
        if (currentStep === step) {
            return `/images/circledFilled${step}.svg`
        } else if (step < currentStep) {
            return '/images/circledCheck.svg'
        } else {
            return `/images/circled${step}.svg`
        }
    }

    const handleStepClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, step: number) => {
        e.preventDefault()

        let isValid = true
        if (currentStep === 1) {
            isValid = validateShipping()
        }
        if (isValid && validateStepSelection(step)) {
            setCurrentStep(step)
        }
    }

    return (
        <>
            <Grid container className={classes.stepsBanner}>
                <Grid item className={classes.stepsItem} xs={1} sm={3} lg={2}>
                    <Link
                        className={currentStep === 1 ? classes.stepLink : ''}
                        href="/"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleStepClick(e, 1)}
                        data-testid={TestId.StepOneLink}
                    >
                        <img src={getIcon(1)} className={classes.stepsIcon} />
                        <Box display={{ xs: 'none', sm: 'inline' }}>{getStepDescription(1)}</Box>
                    </Link>
                </Grid>
                <Box display={{ xs: 'inline', sm: 'none' }}>
                    <Grid item xs={stepCount === 4 ? 2 : 3} className={classes.stepsSpacing}>
                        <hr />
                    </Grid>
                </Box>
                <Grid item className={classes.stepsItem} xs={1} sm={3} lg={2}>
                    <Link
                        className={currentStep === 2 ? classes.stepLink : ''}
                        href="/"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleStepClick(e, 2)}
                        data-testid={TestId.StepTwoLink}
                    >
                        <img src={getIcon(2)} className={classes.stepsIcon} />
                        <Box display={{ xs: 'none', sm: 'inline' }}>{getStepDescription(2)}</Box>
                    </Link>
                </Grid>
                <Box display={{ xs: 'inline', sm: 'none' }}>
                    <Grid item xs={stepCount === 4 ? 2 : 3} className={classes.stepsSpacing}>
                        <hr />
                    </Grid>
                </Box>
                <Grid item className={classes.stepsItem} xs={1} sm={3} lg={2}>
                    <Link
                        className={currentStep === 3 ? classes.stepLink : ''}
                        href="/"
                        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleStepClick(e, 3)}
                        data-testid={TestId.StepThreeLink}
                    >
                        <img src={getIcon(3)} className={classes.stepsIcon} />
                        <Box display={{ xs: 'none', sm: 'inline' }}>{getStepDescription(3)}</Box>
                    </Link>
                </Grid>
                {stepCount === 4 && (
                    <>
                        <Box display={{ xs: 'inline', sm: 'none' }}>
                            <Grid item xs={2} className={classes.stepsSpacing}>
                                <hr />
                            </Grid>
                        </Box>
                        <Grid item className={classes.stepsItem} xs={1} sm={3} lg={2}>
                            <Link
                                className={currentStep === 4 ? classes.stepLink : ''}
                                href="/"
                                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleStepClick(e, 4)}
                                data-testid={TestId.StepFourLink}
                            >
                                <img src={getIcon(4)} className={classes.stepsIcon} />
                                <Box display={{ xs: 'none', sm: 'inline' }}>{getStepDescription(4)}</Box>
                            </Link>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container className={classes.stepsInformation}>
                <Grid item>
                    Step {currentStep}: {getStepDescription(currentStep)}
                </Grid>
            </Grid>
        </>
    )
}
