import React, { FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useStyles from '../styles/useStyles'
import { ROUTE_PATHS } from '../constants/Routes'
import { AppContext } from '../AppContext'
import { AuthType, AzureLoginMessages, ApplicationName } from '../constants/Constants'
import { loginWithAzure } from '../services/UserService'
import { SignInLink } from '../components/SignInLink'
import { Grid, Typography } from '@material-ui/core'
import { useAzureAutoLogin } from '../azure/useAzureAutoLogin'
import { useConfig } from '../hooks/useConfig'

export const Home: FC = () => {
    const classes = useStyles()
    const history = useHistory()
    const [autoLoginInProgress, loginSuccess] = useAzureAutoLogin()
    const [popupFailed, setPopupFailed] = useState<boolean>(false)
    const { authenticationModule, isAuthenticated, updateAuthentication } = useContext(AppContext)
    const { faq } = useConfig()

    useEffect(() => {
        if (!autoLoginInProgress && loginSuccess && isAuthenticated()) {
            onAuthenticated()
        } else if (!autoLoginInProgress && (!loginSuccess || !isAuthenticated())) {
            authenticate()
        }
    }, [autoLoginInProgress, loginSuccess])

    const onAuthenticated = () => {
        history.push(ROUTE_PATHS.PRIVACY)
    }

    const tryAzureLogin = async () => {
        // Azure Login
        let authResult
        try {
            authResult = await authenticationModule.login()
        } catch (error) {
            if (!popupFailed) {
                throw error
            }
        }
        if (authResult) {
            const user = await loginWithAzure(authResult.idToken)
            user.photoIntakeAuthenticationTypeID = AuthType.AzureAd.value
            user.photoIntakeAuthenticationTypeName = AuthType.AzureAd.name
            updateAuthentication(user, authResult.account)
            onAuthenticated()
        } else {
            history.push(ROUTE_PATHS.LOGIN)
        }
    }

    const authenticate = async () => {
        try {
            await tryAzureLogin()
        } catch (error) {
            setPopupFailed(true)
        }
    }

    const useWwidLogin = () => {
        history.push(ROUTE_PATHS.LOGIN)
    }

    const AzureText: FC = ({ children }) => {
        return (
            <Typography variant="h5" component="p">
                {children}
            </Typography>
        )
    }
    return (
        <div className={classes.loginRoot}>
            <Grid container justify="center">
                <Grid item xs={8} className={[classes.title, classes.homeTitle, classes.homePadding].join(' ')}>
                    <div>{ApplicationName}</div>
                </Grid>
                <Grid item xs={8} className={classes.loginText}>
                    <AzureText>
                        {AzureLoginMessages.CheckPopupBlocker}
                        <SignInLink handleLogin={tryAzureLogin} message={AzureLoginMessages.UseAzure} />
                    </AzureText>
                </Grid>
                {!autoLoginInProgress && (
                    <Grid item xs={8} className={classes.loginText}>
                        <Typography variant="body1" component="div">
                            {AzureLoginMessages.PersonalDevice}
                            <SignInLink handleLogin={useWwidLogin} message={AzureLoginMessages.WwidLoginLink} />
                            {AzureLoginMessages.UseWwid}
                            <SignInLink handleLogin={useWwidLogin} message={AzureLoginMessages.WwidLoginLink} />
                        </Typography>
                        <Typography variant="body1" component="div">
                            {AzureLoginMessages.WwidAppFunction}
                        </Typography>
                    </Grid>
                )}
                {!!faq && (
                    <Grid item xs={8} className={[classes.loginText, classes.faq].join(' ')} data-testid="FaqWrapper">
                        <a className={classes.loginLink} title="FAQ" href={faq as string} target="_blank">
                            {AzureLoginMessages.FaqLink}
                        </a>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}
