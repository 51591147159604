export const ROUTE_PATHS = {
    HOME: '/home',
    LOGIN: '/login',
    LOGIN_CODE: '/loginCode',
    ENTRY_CODE: '/entryCode',
    PRIVACY: '/privacy',
    BADGE_OFFICES: '/badgeoffices',
    WORKFLOW_OPTIONS: '/workflowoptions',
    REQUEST_NEW_BADGE: '/requestnewbadge',
    REPLACE_EXISTING_BADGE: '/replaceexistingbadge',
    REPLACE_EXISTING_PHOTO: '/replaceexistingphoto',
    ACTIVATE_BADGE: '/activatebadge',
    REACTIVATE_BADGE: '/reactivatebadge',
    DEACTIVATE_BADGE: '/deactivatebadge',
    PHOTO_SENT: '/photosent',
}
