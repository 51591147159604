import React, { FC, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { AppContext } from '../AppContext'
import useStyles from '../styles/useStyles'
import { AuthType, AzureLoginMessages } from '../constants/Constants'
import { LoginComponent } from '../components/LoginComponent'
import AzureAuthenticationComponent from '../azure/AzureAuthenticationComponent'
import { loginWithAzure } from '../services/UserService'
import { ROUTE_PATHS } from '../constants/Routes'
import { IUser } from '../interfaces/IUser'
import { useAzureAutoLogin } from '../azure/useAzureAutoLogin'
import { useConfig } from 'hooks/useConfig'
import { Grid } from '@material-ui/core'

export const Login: FC = () => {
    const { authenticationModule, isAuthenticated, updateAuthentication } = useContext(AppContext)
    const [autoLoginInProgress, loginSuccess] = useAzureAutoLogin()
    const classes = useStyles()
    const history = useHistory()
    const { faq } = useConfig()

    useEffect(() => {
        if (!autoLoginInProgress && loginSuccess && isAuthenticated()) {
            onAuthenticated(true)
        }
    }, [autoLoginInProgress, loginSuccess])

    const onAuthenticated = (azureLogin?: boolean, user?: IUser | null) => {
        !!!user?.requiresMFA || azureLogin ? history.push(ROUTE_PATHS.PRIVACY) : history.push(ROUTE_PATHS.LOGIN_CODE, user)
    }

    const tryAzureLogin = async (): Promise<void> => {
        // Azure Login
        const authResult = await authenticationModule.login()
        if (authResult) {
            const user = await loginWithAzure(authResult.idToken)
            user.photoIntakeAuthenticationTypeID = AuthType.AzureAd.value
            user.photoIntakeAuthenticationTypeName = AuthType.AzureAd.name
            updateAuthentication(user, authResult.account)
            onAuthenticated(true)
        }
    }

    return (
        <div className={classes.loginRoot}>
            {autoLoginInProgress ? (
                <div className={classes.loginText}>{AzureLoginMessages.AutoLoginInProgress}</div>
            ) : (
                <>
                    <LoginComponent onAuthenticated={onAuthenticated} />
                    <AzureAuthenticationComponent login={tryAzureLogin} />
                    {!!faq && (
                        <Grid container justify="center">
                            <Grid item xs={8} className={[classes.loginText, classes.faq].join(' ')} data-testid="FaqWrapper">
                                <a className={classes.loginLink} title="FAQ" href={faq as string} target="_blank">
                                    {AzureLoginMessages.FaqLink}
                                </a>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </div>
    )
}
