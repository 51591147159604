import { useMsal } from '@azure/msal-react'
import { useContext, useEffect } from 'react'
import { loginRequest } from '../authConfig'
import AzureModule from './AzureAuthenticationContext'
import { AppContext } from '../AppContext'
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser'

export const useAzureAutoLogin = (): [boolean, boolean] => {
    const msalContext = useMsal()
    const { updateAuthentication, autoLoginData } = useContext(AppContext)
    const { autoLoginInProgress, setAutoLoginInProgress, loginSuccess, setLoginSuccess, hasAttemptedLogin, setHasAttemptedLogin } = autoLoginData

    const handleAuthResult = async (authResult?: AuthenticationResult | null) => {
        const [user, account] = await AzureModule.handleAuthResult(authResult)
        updateAuthentication(user, account)
        if (user && account && setLoginSuccess) {
            setLoginSuccess(true)
        }
    }

    const attemptAcquireToken = async () => {
        console.log('Using acquire token')
        try {
            const authResult = await msalContext?.instance?.acquireTokenSilent({ scopes: loginRequest.scopes, account: msalContext?.accounts?.[0] })
            await handleAuthResult(authResult)
        } catch (error) {
            console.log(error)
        } finally {
            setAutoLoginInProgress && setAutoLoginInProgress(false)
        }
    }

    useEffect(() => {
        if (msalContext.inProgress !== InteractionStatus.Startup && !hasAttemptedLogin) {
            setHasAttemptedLogin && setHasAttemptedLogin(true)
            attemptAcquireToken()
        }
    }, [msalContext, hasAttemptedLogin])

    return [autoLoginInProgress, loginSuccess]
}
