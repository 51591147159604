import React, { FC } from 'react'
import { RemotelyProcessedImageProps } from 'types/ImageTypes'
import useStyles from 'styles/useStyles'
import { Backdrop, CircularProgress, Grid } from '@material-ui/core'
import { TestId } from 'constants/testIds'

const RemoteProcessedPhoto: FC<RemotelyProcessedImageProps> = ({ processingError, isProcessing, children }) => {
    const classes = useStyles()

    return (
        <Grid container>
            {processingError.length > 0 && (
                <Grid item xs={12}>
                    <div className={classes.error}>{processingError}</div>
                </Grid>
            )}
            <Grid item xs={12}>
                <Backdrop data-testid={TestId.Backdrop} className={classes.backdrop} open={isProcessing}>
                    <Grid container direction="column">
                        <Grid item>
                            <div>Photo Processing ...</div>
                        </Grid>
                        <Grid item>
                            <CircularProgress data-testid={TestId.ProgressCircle} color="inherit" />
                        </Grid>
                    </Grid>
                </Backdrop>
                {children}
            </Grid>
        </Grid>
    )
}

export default RemoteProcessedPhoto
