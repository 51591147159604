import { Configuration, LogLevel } from '@azure/msal-browser'
import { getCookieConsentValue } from 'react-cookie-consent'

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito
const cookieConsent = getCookieConsentValue() === 'true'

let msalConfig: Configuration | null = null
// Config object to be passed to Msal on creation
export const getMsalConfig = (clientID: string, tenantID: string): Configuration => {
    if (msalConfig) {
        return msalConfig
    }
    msalConfig = {
        auth: {
            clientId: clientID,
            authority: 'https://login.microsoftonline.com/' + tenantID,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: cookieConsent && (isIE || isEdge || isFirefox),
            secureCookies: true,
        },
        system: {
            loggerOptions: {
                loggerCallback: (level: number, message: string, containsPii: boolean): void => {
                    if (containsPii) {
                        return
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(message)
                            return
                        case LogLevel.Info:
                            console.info(message)
                            return
                        case LogLevel.Verbose:
                            console.debug(message)
                            return
                        case LogLevel.Warning:
                            console.warn(message)
                            return
                        default:
                            return
                    }
                },
            },
        },
    }
    return msalConfig
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['User.Read'],
    prompt: 'select_account',
}
