import { Container, Grid } from '@material-ui/core'
import { AppContext } from 'AppContext'
import { DeactivateBadgeText, RequestType } from 'constants/Constants'
import { useContext, useEffect, useState } from 'react'
import useStyles from 'styles/useStyles'
import { FCWithAuth } from 'types/CommonTypes'
import { IActivateBadgeRequest } from '../interfaces/IActivateBadgeRequest'
import { activateBadgeRequest } from '../services/ImageService'

export const DeactivateBadge: FCWithAuth = () => {
    const { user } = useContext(AppContext)
    const classes = useStyles()
    const [time, setTime] = useState<number>(0)

    useEffect(() => {
        setTime(5)
        const activate = async () => {
            const payload: IActivateBadgeRequest = {
                wwId: user?.wwId,
                photoIntakeRequestTypeID: RequestType.DeactivateBadge.value,
                photoIntakeRequestTypeName: RequestType.DeactivateBadge.name,
            }
            try {
                const response = await activateBadgeRequest(payload)
                if (response.startsWith('ERROR -')) {
                    alert('useEffet - API error')
                }
            } catch (error) {
                console.log('useEffect-error', error)
            }
        }
        activate()
    }, [])

    return (
        <div className={classes.root}>
            <Container maxWidth="md">
                <Grid container className={classes.badgePadding}>
                    <Grid item xs={12}>
                        <Grid container className={classes.textInput}>
                            <Grid item xs={12}>
                                <div className={classes.title}>
                                    <h1>{DeactivateBadgeText.Title}</h1>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.instructionsChoiceText}>
                            {DeactivateBadgeText.Messaging1} {time} {DeactivateBadgeText.Messaging2}
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

DeactivateBadge.requiresAuth = true
DeactivateBadge.requiresAzure = true
