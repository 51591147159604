import { Button, Grid } from '@material-ui/core'
import React, { FC, RefObject, createRef } from 'react'

import useStyles from '../styles/useStyles'
import { PhotoCaptureOption, SUPPORTED_FILE_TYPES } from '../constants/Constants'
import { TestId } from 'constants/testIds'

interface IProps {
    attachLocalFile: (file: File) => void | Promise<void>
}

export const AttachPhotoComponent: FC<IProps> = ({ attachLocalFile }) => {
    const classes = useStyles()

    const attachImageRef: RefObject<HTMLInputElement> = createRef()

    const handleAttachImage = async () => {
        if (attachImageRef.current && attachImageRef.current.files && attachImageRef.current.files.length > 0) {
            attachLocalFile(attachImageRef.current.files[0])
        }
    }

    return (
        <>
            {PhotoCaptureOption.AttachImage && (
                <Grid container className={classes.attachPhotoContainer}>
                    <Grid item xs={12} className={classes.governmentIdAttachImageInput}>
                        <input accept={`${SUPPORTED_FILE_TYPES.join(',')}`} id="uploadFile" type="file" ref={attachImageRef} />
                    </Grid>
                    <Grid item xs={12} className={classes.governmentIdAttachImageText}>
                        {`Images must be in one of the following formats: ${SUPPORTED_FILE_TYPES.join(', ')}.`}
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleAttachImage} data-testid={TestId.AttachImageButton}>
                            Attach Image
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    )
}
