import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Container, Typography } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { Grid } from '@material-ui/core'
import { ApplicationName, ErrorMessage, Regex, ShippingNotificationOption, TwoAuthVerificationText } from 'constants/Constants'
import { LoginCodeComponent } from '../components/LoginCodeComponent'
import { ROUTE_PATHS } from 'constants/Routes'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import { PasscodeValues } from 'types/PasscodeTypes'
import { AppContext } from 'AppContext'
import { handlePasscodeSubmission } from 'utils/PasscodeHandlers'
import { useSnackbar } from 'notistack'
import { FCWithAuth } from 'types/CommonTypes'

interface Errors {
    [key: string]: string[] | undefined
}

export const LoginCode: FCWithAuth = () => {
    const classes = useStyles()
    const history = useHistory<History>()
    const [isValid, setIsValid] = useState<boolean>(false)
    const [errors, setErrors] = useState<Errors>()
    const { user } = useContext(AppContext)
    const { enqueueSnackbar } = useSnackbar()

    !user && history.push(ROUTE_PATHS.LOGIN)

    const defaultValues = {
        passcodeOption: ShippingNotificationOption.Email.name,
        email: '',
        smsNumber: '',
        wwid: user?.wwId ?? '',
        user: null,
    }

    const [passcodeData, setPasscodeData] = useState<PasscodeValues>(defaultValues)

    const validate = () => {
        if (passcodeData.passcodeOption === ShippingNotificationOption.Email.name) {
            const regex = new RegExp(Regex.Email)
            const emailValid = passcodeData.email.trim().length > 0 && regex.test(passcodeData.email)
            emailValid ? setErrors(undefined) : setErrors({ email: [ErrorMessage.Email], smsNumber: undefined })
            setIsValid(emailValid)
        } else {
            const smsValid = isPossiblePhoneNumber(passcodeData.smsNumber)
            smsValid ? setErrors(undefined) : setErrors({ smsNumber: [ErrorMessage.SmsNumber], email: undefined })
            setIsValid(smsValid)
        }
    }

    useEffect(() => {
        validate()
    }, [passcodeData])

    return (
        <div className={classes.loginRoot}>
            <Grid container justify="center">
                <Grid item xs={8}>
                    <div className={[classes.title, classes.homeTitle, classes.homePadding].join(' ')}>
                        <h1>{ApplicationName}</h1>
                    </div>
                </Grid>
            </Grid>
            <Container maxWidth="sm">
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center' }}>
                            <p>{TwoAuthVerificationText.ChoosingWayToReceive}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ textAlign: 'center' }}>
                            <p>{TwoAuthVerificationText.ConsentForOTP}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <LoginCodeComponent setPasscodeData={setPasscodeData} passcodeData={passcodeData} />
                        {errors &&
                            errors.email?.map((item) => {
                                return (
                                    <Typography variant="subtitle1" color="error">
                                        {item}
                                    </Typography>
                                )
                            })}
                        {errors &&
                            errors.smsNumber?.map((item) => {
                                return (
                                    <Typography variant="subtitle1" color="error">
                                        {item}
                                    </Typography>
                                )
                            })}
                    </Grid>
                    <Button
                        onClick={() => handlePasscodeSubmission(passcodeData, isValid, history, enqueueSnackbar)}
                        variant="contained"
                        color="default"
                        type="submit"
                        disabled={!isValid}
                    >
                        Continue
                    </Button>
                </Grid>
            </Container>
        </div>
    )
}

LoginCode.requiresAuth = true
