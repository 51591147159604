import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        color: '#00285A',
        flexGrow: 1,
    },
    site: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    siteContent: {
        flex: '1 0 auto',
        paddingBottom: theme.spacing(3),
        width: '100%',
        '&:after': {
            content: '""',
            display: 'block',
            marginTop: 'var(--space)',
            height: '0',
            visibility: 'hidden',
        },
    },
    menuButton: {
        paddingLeft: '5px',
        paddingRight: '5px',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    title: {
        fontSize: '36px',
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(3),
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '21px',
        },
        color: theme.palette.primary.main,
    },
    homeTitle: {
        color: 'white',
    },
    homePadding: {
        marginTop: '24px',
    },
    loginText: {
        fontSize: '15px',
        paddingTop: theme.spacing(3),
        textAlign: 'center',
    },
    loginButton: {
        backgroundColor: '#76CEFF',
        fontFamily: "'Intel Clear', system-ui, 'Segoe UI', Helvetica, sans-serif",
        marginTop: theme.spacing(1),
    },
    loginInput: {
        backgroundColor: 'white',
        color: '#757575',
    },
    loginLink: {
        color: 'white',
        textDecoration: 'underline solid !important',
        '&:hover': {
            color: 'white',
            cursor: 'pointer',
        },
    },
    loginLogo: {
        height: '60px',
        paddingTop: theme.spacing(3),
    },
    loginRoot: {
        backgroundColor: '#0068b5',
        color: 'white',
        flexGrow: 1,
        height: '95vh',
    },
    navBarLogo: {
        height: '22px',
        paddingRight: theme.spacing(2),
    },
    navBarTitle: {
        flexGrow: 1,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(3),
    },
    navBarLink: {
        color: 'white',
        '&:hover': {
            color: 'white',
        },
    },
    navBarLogout: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    footer: {
        backgroundColor: '#E9E9E9',
        color: '#00285A',
        flex: 'none',
        padding: theme.spacing(3),
    },
    copyrightWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    centerWrapper: {
        display: 'flex',
        justifyContent: 'center',
    },
    copyrightLegalNav: {
        display: 'flex',
        marginLeft: 'auto',
    },
    disabledItem: {
        opacity: 0.5,
        cursor: 'default !important',

        '& *': {
            cursor: 'default !important',
        },
    },
    instructionsGroupContainer: {
        [theme.breakpoints.down('xs')]: {
            borderTop: '1px solid #CCCCCC',
            '&:last-of-type': {
                borderBottom: '1px solid #CCCCCC',
            },
        },
    },
    instructionsImage: {
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'start',
            margin: theme.spacing(1),
            '& img': {
                height: '4rem',
                width: '4rem',
            },
        },
    },
    instructionsText: {
        display: 'flex',
        fontSize: '24px',
        justifyContent: 'center',
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(5),
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            fontSize: '21px',
        },
    },
    instructionsChoiceText: {
        alignItems: 'center',
        display: 'flex',
        fontSize: '18px',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'start',
        },
    },
    instructionsToolTipImage: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
    },
    tooltipTitle: {
        backgroundColor: '#E9E9E9',
        padding: '8px 8px 0px 8px',
    },
    stepLink: {
        display: 'inline-block',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            '&::after': {
                position: 'absolute',
                content: '""',
                borderBottom: '4px solid #00285A',
                width: '100%',
                bottom: '-8px',
                left: '0%',
            },
        },
    },
    stepsBanner: {
        alignContent: 'center',
        backgroundColor: '#E9E9E9',
        display: 'flex',
        height: '3rem',
        justifyContent: 'center',
    },
    stepsInformation: {
        alignContent: 'center',
        backgroundColor: '#E9E9E9',
        display: 'flex',
        fontSize: 'small',
        height: '1.5rem',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    stepsItem: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: 'small',
        },
    },
    stepsIcon: {
        height: '2rem',
        marginRight: theme.spacing(0.5),
        width: '2rem',
    },
    stepsSpacing: {
        backgroundColor: 'darkgray',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
    },
    textInput: {
        justifyContent: 'center',
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),

        '& .MuiOutlinedInput-input': {
            borderRadius: '4px',
            backgroundColor: theme.palette.common.white,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 0px) scale(0.75)',
        },
    },
    text: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
        },
    },
    disabledTextInput: {
        border: '1px solid #AAAAAA',
        borderRadius: '4px',
        '& .MuiFilledInput-underline:before': {
            borderBottom: 'none',
        },
    },
    receiveBadgeTitle: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(4),
    },
    shippingCarrierSelect: {
        textAlign: 'left',
        width: '100%',
    },
    cameraSelect: {
        marginBottom: theme.spacing(1),
    },
    viewPhotoTop: {
        flexGrow: 1,
        marginTop: '38px',
        textAlign: 'center',
    },
    viewPhotoStatusText: {
        fontSize: '46px',
        marginLeft: '10px',
        verticalAlign: 'middle',
        color: theme.palette.primary.main,
    },
    viewPhotoText: {
        fontSize: '18px',
        marginBottom: '28px',
        marginTop: '17px',
    },
    viewPhotoCapturedImage: {
        maxWidth: '495px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '279px',
        },
    },
    viewPhotoButton: {
        width: '100%',
    },
    summaryImage: {
        maxWidth: '504px',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '288px',
        },
    },
    governmentIdAttachImageInput: {
        border: '1px solid #CCCCCC',
        marginBottom: '10px',
        padding: '10px',
    },
    governmentIdAttachImageText: {
        marginBottom: '20px',
    },
    photoBorder: {
        border: '1px solid #CCCCCC',
        marginTop: '32px',
        padding: '23px',
    },
    takePhotoCameraContainer: {
        marginTop: '50px',
        textAlign: 'center',
    },
    photoChoice: {
        marginLeft: '25px',
        textAlign: 'left',
    },
    skipGovtIdGrid: {
        marginTop: '50px',
    },
    attachPhotoContainer: {
        marginTop: '50px',
        textAlign: 'center',
    },
    takePhotoButton: {
        marginTop: '50px',
    },
    takePhotoInstructionsIconGroup: {
        marginBottom: '17px',
        marginTop: '18px',
        textAlign: 'end',
    },
    takePhotoInstructionsTextGroup: {
        marginBottom: '17px',
        marginTop: '18px',
    },
    takePhotoInstructionsIcon: {
        height: '30px',
        width: '30px',
    },
    takePhotoInstructionsText: {
        fontSize: '18px',
        marginLeft: '11px',
    },
    takePhotoInstructionsButton: {
        marginLeft: '30px',
        marginRight: '30px',
        marginTop: '33px',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    summaryNotificationText: {
        fontSize: '24px',
        marginTop: '20px',
    },
    summarySectionTitleText: {
        fontSize: '24px',
        marginBottom: '20px',
    },
    summaryFinishButton: {
        marginTop: '33px',
    },
    photoSentContainer: {
        textAlign: 'center',
    },
    photoSentTitle: {
        fontSize: '36px',
        marginTop: '206px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '21px',
            marginTop: '60px',
        },
        color: theme.palette.primary.main,
    },
    photoSentDescription: {
        fontSize: '18px',
        marginBottom: '29px',
        marginTop: '26px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '15px',
        },
    },
    photoSentNotification: {
        marginTop: '12px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '15px',
        },
    },
    attachError: {
        color: theme.palette.error.main,
        textAlign: 'center',
    },
    error: {
        color: theme.palette.error.main,
        textAlign: 'left',
    },
    inverse: {
        color: `${theme.palette.common.white} !important`,
    },
    badgePadding: {
        marginTop: '80px',
        paddingTop: '50px',
    },
    faq: {
        marginTop: '50px',
    },
}))

export default useStyles
