import React, { FC, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { AuthType, RequestType, ShippingReceiveOption, ShippingReceiveOptionText } from '../constants/Constants'
import { ShippingValues } from '../types/ShippingValuesTypes'
import { TestId } from 'constants/testIds'
import { AppContext } from 'AppContext'
import TextFieldWithCharacterCount from './TextFieldWithCharacterCount'

interface IProps {
    handleOptionChange: () => void
    requestType?: number
}

export const ShippingReceiveComponent: FC<IProps> = ({ handleOptionChange, requestType }) => {
    const classes = useStyles()
    const { user } = useContext(AppContext)
    const disabledWwID = user?.photoIntakeAuthenticationTypeID === AuthType.WwId.value

    const {
        control,
        formState: { errors },
    } = useFormContext<ShippingValues>()

    return (
        <div>
            <Grid item xs={12}>
                <div className={classes.receiveBadgeTitle} style={disabledWwID ? { opacity: 0.5 } : undefined}>
                    <h4>How would you like to receive your badge?</h4>
                </div>
            </Grid>
            <Grid item xs={12} data-testid={TestId.ShippingReceiveGrid}>
                <div style={{ textAlign: 'center' }}>
                    <Controller
                        render={({ field }) => (
                            <RadioGroup {...field} onChange={handleOptionChange}>
                                <FormControlLabel
                                    value={JSON.stringify(ShippingReceiveOption.Campus)}
                                    control={<Radio color="primary" />}
                                    style={{ textAlign: 'left' }}
                                    label={ShippingReceiveOptionText.Campus}
                                    disabled={disabledWwID}
                                />
                                {field.value === JSON.stringify(ShippingReceiveOption.Campus) && (
                                    <>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            required
                                                            label="Campus"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.Campus, maxLength: 1000 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="campusName"
                                                    control={control}
                                                />
                                                {errors.campusName && <div className={classes.error}>{errors.campusName.message}</div>}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            label="Mailstop"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.MailStop, maxLength: 52 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="mailStop"
                                                    control={control}
                                                />
                                                {errors.mailStop && <div className={classes.error}>{errors.mailStop.message}</div>}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                <FormControlLabel
                                    value={JSON.stringify(ShippingReceiveOption.Ship)}
                                    control={<Radio color="primary" />}
                                    style={{ textAlign: 'left' }}
                                    label={
                                        requestType === RequestType.RequestNewBadge.value
                                            ? `${ShippingReceiveOptionText.Ship} ${ShippingReceiveOptionText.FirstTime}`
                                            : ShippingReceiveOptionText.Ship
                                    }
                                    disabled={disabledWwID}
                                />
                                {field.value === JSON.stringify(ShippingReceiveOption.Ship) && (
                                    <>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            required
                                                            label="Address Line 1"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.AddressOne, maxLength: 255 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="addressOne"
                                                    control={control}
                                                />
                                                {errors.addressOne && <div className={classes.error}>{errors.addressOne.message}</div>}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            label="Address Line 2"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.AddressTwo, maxLength: 255 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="addressTwo"
                                                    control={control}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            required
                                                            label="City"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.City, maxLength: 255 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="city"
                                                    control={control}
                                                />
                                                {errors.city && <div className={classes.error}>{errors.city.message}</div>}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            required
                                                            label="State / Province / Area"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.State, maxLength: 255 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="state"
                                                    control={control}
                                                />
                                                {errors.state && <div className={classes.error}>{errors.state.message}</div>}
                                            </Grid>
                                        </Grid>
                                        <Grid container className={classes.textInput}>
                                            <Grid item xs={12} style={{ textAlign: 'left' }}>
                                                <Controller
                                                    render={({ field }) => (
                                                        <TextFieldWithCharacterCount
                                                            fullWidth
                                                            required
                                                            label="Zip"
                                                            variant="outlined"
                                                            {...field}
                                                            inputProps={{ 'data-testid': TestId.Zip, maxLength: 255 }}
                                                            disabled={disabledWwID}
                                                        />
                                                    )}
                                                    name="zip"
                                                    control={control}
                                                />
                                                {errors.zip && <div className={classes.error}>{errors.zip.message}</div>}
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </RadioGroup>
                        )}
                        name="receiveOption"
                        control={control}
                    />
                    <Typography variant="body1" component="div">
                        {ShippingReceiveOptionText.NonAzureAuthShipping}
                    </Typography>
                </div>
            </Grid>
        </div>
    )
}
