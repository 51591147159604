import React, { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Grid, Tooltip, withStyles } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'

import { AppContext } from '../AppContext'
import { ROUTE_PATHS } from '../constants/Routes'
import useStyles from '../styles/useStyles'
import { RequestType, ApplicationName, AzureLoginMessages, AuthType } from '../constants/Constants'

import image1 from '../images/RequestNew.svg'
import image2 from '../images/ReplaceExisting.svg'
import image3 from '../images/TakeNewPhoto.svg'
import image4 from '../images/ActivateBadge.svg'
import { FCWithAuth } from 'types/CommonTypes'
import { TestId } from 'constants/testIds'
import { IUserAccess } from 'interfaces/IUser'

interface IProps {
    styles: ClassNameMap
    imageSource: string
    instructions: string
    tipText: string
    testId: string
    onClickFunction(): void
    disabled?: boolean
}

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: 'white',
        border: '1px solid #dadde9',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    tooltipPlacementBottom: {
        padding: 0,
    },
    tooltipPlacementLeft: {
        padding: 0,
    },
    tooltipPlacementRight: {
        padding: 0,
    },
    tooltipPlacementTop: {
        padding: 0,
    },
}))(Tooltip)

const TipGroup: FC<IProps> = ({ styles, imageSource, instructions, tipText, testId, onClickFunction, disabled }) => {
    return (
        <Grid container direction="column">
            <Grid item className={disabled ? styles.disabledItem : undefined}>
                <Grid container>
                    <Grid item xs={2} sm={12} className={styles.instructionsImage}>
                        <img src={imageSource} alt=" " onClick={!disabled ? onClickFunction : undefined} />
                    </Grid>
                    <Grid item xs={8} sm={12} className={styles.instructionsChoiceText}>
                        <div onClick={!disabled ? onClickFunction : undefined} data-testid={testId}>
                            {instructions}
                        </div>
                    </Grid>
                    <Grid item xs={1} sm={12} className={styles.instructionsToolTipImage}>
                        <HtmlTooltip
                            arrow
                            disableFocusListener
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            title={
                                <Grid container direction="column">
                                    <Grid item className={styles.tooltipTitle}>
                                        <h6>{instructions}</h6>
                                    </Grid>
                                    <Grid item style={{ padding: '8px 8px 0px 8px' }}>
                                        <p>{disabled ? AzureLoginMessages.NonAzureLoginTooltip : tipText}</p>
                                    </Grid>
                                </Grid>
                            }
                        >
                            <InfoOutlinedIcon />
                        </HtmlTooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const WorkflowOptions: FCWithAuth = () => {
    const classes = useStyles()
    const history = useHistory()
    const { user } = useContext(AppContext)

    const userAccess: IUserAccess | undefined = user?.userAccess
    const handleRequestNewBadge = () => {
        history.push(ROUTE_PATHS.REQUEST_NEW_BADGE)
    }

    const handleReplaceExistingBadge = () => {
        history.push(ROUTE_PATHS.REPLACE_EXISTING_BADGE)
    }

    const handleReplaceExistingPhoto = () => {
        history.push(ROUTE_PATHS.REPLACE_EXISTING_PHOTO)
    }

    const handleActivateBadge = () => {
        history.push(ROUTE_PATHS.ACTIVATE_BADGE)
    }
    const handleReactivateBadge = () => {
        history.push(ROUTE_PATHS.REACTIVATE_BADGE)
    }
    const handleDeactivateBadge = () => {
        history.push(ROUTE_PATHS.DEACTIVATE_BADGE)
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                Hello, {user?.firstName}.
                <br />
                Welcome to the {ApplicationName} web application
            </div>
            <div className={classes.instructionsText}>
                {userAccess && Object.keys(userAccess).every((k) => !userAccess[k as keyof IUserAccess])
                    ? 'At this time there are no available actions you can take.'
                    : 'What would you like to do?'}
            </div>
            <Grid container style={{ justifyContent: 'center' }}>
                {userAccess?.newBadge && (
                    <Grid item xs={12} sm={6} md={3} className={classes.instructionsGroupContainer}>
                        <TipGroup
                            styles={classes}
                            imageSource={image1}
                            instructions={RequestType.RequestNewBadge.name}
                            tipText="To request an Intel Corporation Identity Badge you must have a valid Intel issued Worldwide Identifier (WWID)."
                            testId={TestId.WorkflowOptionRequestNewBadge}
                            onClickFunction={handleRequestNewBadge}
                        />
                    </Grid>
                )}
                {userAccess?.replaceBadge && user?.photoIntakeAuthenticationTypeID !== AuthType.WwId.value && (
                    <Grid item xs={12} sm={6} md={3} className={classes.instructionsGroupContainer}>
                        <TipGroup
                            styles={classes}
                            imageSource={image2}
                            instructions={RequestType.ReplaceExistingBadge.name}
                            tipText="Use this link to request a replacement Intel Corporation Identity Badge."
                            testId={TestId.WorkflowOptionReplaceExistingBadge}
                            onClickFunction={handleReplaceExistingBadge}
                            disabled={user?.photoIntakeAuthenticationTypeID === AuthType.WwId.value}
                        />
                    </Grid>
                )}
                {userAccess?.replacePhoto && (
                    <Grid item xs={12} sm={6} md={3} className={classes.instructionsGroupContainer}>
                        <TipGroup
                            styles={classes}
                            imageSource={image3}
                            instructions={RequestType.ReplaceExistingPhoto.name}
                            tipText="Use this link to replace your existing Intel Corporation Badge Photo."
                            testId={TestId.WorkflowOptionReplaceExistingPhoto}
                            onClickFunction={handleReplaceExistingPhoto}
                        />
                    </Grid>
                )}
                {userAccess?.activateBadge && user?.photoIntakeAuthenticationTypeID !== AuthType.WwId.value && (
                    <Grid item xs={12} sm={6} md={3} className={classes.instructionsGroupContainer}>
                        <TipGroup
                            styles={classes}
                            imageSource={image4}
                            instructions={RequestType.ActivateBadge.name}
                            tipText="Your new Intel Corporation Identification Badge will be shipped to you disabled. Use this link to activate your new badge after it arrives. Any temporary issued badge will be disabled when you make the activation."
                            testId={TestId.WorkflowOptionActivateBadge}
                            onClickFunction={handleActivateBadge}
                            disabled={user?.photoIntakeAuthenticationTypeID === AuthType.WwId.value}
                        />
                    </Grid>
                )}
                {userAccess?.reactivateBadge && user?.photoIntakeAuthenticationTypeID !== AuthType.WwId.value && (
                    <Grid item xs={12} sm={6} md={3} className={classes.instructionsGroupContainer}>
                        <TipGroup
                            styles={classes}
                            imageSource={image4}
                            instructions={RequestType.ReactivateBadge.name}
                            tipText="Use this link to reactivate your badge. Any temporary issued badge will be disabled when you make the activation."
                            testId={TestId.WorkflowOptionReactivateBadge}
                            onClickFunction={handleReactivateBadge}
                            disabled={user?.photoIntakeAuthenticationTypeID === AuthType.WwId.value}
                        />
                    </Grid>
                )}
                {userAccess?.deactivateBadge && user?.photoIntakeAuthenticationTypeID !== AuthType.WwId.value && (
                    <Grid item xs={12} sm={6} md={3} className={classes.instructionsGroupContainer}>
                        <TipGroup
                            styles={classes}
                            imageSource={image4}
                            instructions={RequestType.DeactivateBadge.name}
                            tipText="Use this link to deactivate your badge."
                            testId={TestId.WorkflowOptionDeactivateBadge}
                            onClickFunction={handleDeactivateBadge}
                            disabled={user?.photoIntakeAuthenticationTypeID === AuthType.WwId.value}
                        />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

WorkflowOptions.requiresAuth = true
