import React, { FC, useContext, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Avatar from 'react-avatar'

import { AppContext } from '../AppContext'
import useStyles from '../styles/useStyles'
import { TestId } from '../constants/testIds'

export const SignOutButton: FC = () => {
    const { authenticationModule, isAuthenticated, updateAuthentication, azureAccountInfo, user } = useContext(AppContext)

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLButtonElement>()
    const open = Boolean(anchorEl)

    const handleLogout = () => {
        setAnchorEl(undefined)

        if (azureAccountInfo !== null) {
            authenticationModule.logout(azureAccountInfo)
        }
        updateAuthentication(null, null)
    }

    return (
        <div>
            {isAuthenticated() && (
                <>
                    <Avatar
                        name={user?.firstName + ' ' + user?.lastName}
                        round
                        size="41"
                        className={classes.navBarLogout}
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                    />
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={() => setAnchorEl(undefined)}
                    >
                        <MenuItem onClick={handleLogout} key="logout" data-testid={TestId.LogOutMenuItem}>
                            Logout
                        </MenuItem>
                    </Menu>
                </>
            )}
        </div>
    )
}
