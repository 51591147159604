import React, { FC, ReactElement } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
            opacity: '0.3',
        },
        progress: {
            height: '100px !important',
            width: '100px !important',
        },
    })
)

interface ISpinnerTextProps {
    title: string
    variant: Variant
}

const LoadingSpinnerBackdrop = (): ReactElement => {
    const classes = useStyles()
    const [progress, setProgress] = React.useState(0)

    React.useEffect(() => {
        function tick() {
            setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1))
        }

        const timer = setInterval(tick, 80)
        return () => {
            clearInterval(timer)
        }
    }, [])

    return (
        <div>
            <Backdrop className={classes.backdrop} open>
                <CircularProgress variant="determinate" value={progress} className={classes.progress} />
            </Backdrop>
        </div>
    )
}

export default LoadingSpinnerBackdrop

export const LoadingSpinnerWithText: FC<ISpinnerTextProps> = ({ title, variant }) => {
    return (
        <Typography variant={variant} color="primary" style={{ margin: '15px 0' }}>
            <CircularProgress color="inherit" size={25} style={{ marginRight: '15px' }} />
            {title}
        </Typography>
    )
}
