import React, { FC } from 'react'

import useStyles from '../styles/useStyles'
import { TestId } from 'constants/testIds'

interface IProps {
    handleLogin: () => Promise<void> | void
    message?: string
}

export const SignInLink: FC<IProps> = ({ handleLogin, message = 'Use your Azure® credentials' }) => {
    const classes = useStyles()

    return (
        <a className={classes.loginLink} onClick={handleLogin} data-testid={TestId.SignInLink}>
            {message}
        </a>
    )
}
