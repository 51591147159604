import React, { FC, useState } from 'react'
import { Container, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'

import useStyles from '../styles/useStyles'
import { GovtIdCaptureOption, GovtIdCaptureOptionText, GovtIdSkipText, ViewPhotoText } from '../constants/Constants'
import { TakePhotoComponent } from './TakePhotoComponent'
import { ViewPhotoComponent } from './ViewPhotoComponent'
import { TestId } from '../constants/testIds'
import { AttachPhotoComponent } from './AttachPhotoComponent'
import { useImageProcessing } from 'hooks/useImageProcessing'
import ActionModal, { ICommand } from './ActionModal'

interface IProps {
    handleViewPhotoContinue: (image: string | null) => void
}

export const GovernmentId: FC<IProps> = ({ handleViewPhotoContinue }) => {
    const classes = useStyles()
    const [captureOption, setCaptureOption] = useState('')
    const [confirmSkipOpen, setConfirmSkipOpen] = useState<boolean>(false)
    const { isProcessing, processingError, invalidImageReason, capturedImage, attachLocalFile, setImage, retakePhoto } = useImageProcessing(false)

    const showViewComponent = capturedImage || invalidImageReason

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCaptureOption((event.target as HTMLInputElement).value)
        event.target.value === GovtIdCaptureOption.Skip && setConfirmSkipOpen(true)
    }

    const handleCloseSkip = () => {
        setConfirmSkipOpen(false)
        setCaptureOption('')
    }

    const handleRetakePhoto = () => {
        setCaptureOption('')
        retakePhoto()
    }

    const dialogActions: ICommand[] = [
        {
            autoFocus: true,
            color: 'primary',
            label: GovtIdSkipText.SkipContinue,
            canExecute: true,
            execute: () => handleViewPhotoContinue(null),
        },
        {
            autoFocus: true,
            color: 'default',
            label: 'Cancel',
            canExecute: true,
            execute: handleCloseSkip,
        },
    ]

    return (
        <div className={classes.root}>
            <Container maxWidth="sm" data-testid={TestId.GovtIdContainer}>
                {!showViewComponent && (
                    <Grid item xs={12}>
                        <Grid container className={classes.textInput}>
                            <Grid item xs={12}>
                                <div className={classes.title}>
                                    <h1>Capture Government ID</h1>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup onChange={handleOptionChange}>
                                    <FormControlLabel
                                        name="takePhotoOption"
                                        value={GovtIdCaptureOption.TakePhoto}
                                        control={<Radio color="primary" data-testid={TestId.GovtIdTakePhotoOption} />}
                                        label={GovtIdCaptureOptionText.TakePhoto}
                                    />
                                    <FormControlLabel
                                        name="attachImageOption"
                                        value={GovtIdCaptureOption.AttachImage}
                                        control={<Radio color="primary" data-testid={TestId.GovtIdUploadPhotoOption} />}
                                        label={GovtIdCaptureOptionText.AttachImage}
                                    />
                                    <FormControlLabel
                                        name="skipOption"
                                        value={GovtIdCaptureOption.Skip}
                                        control={<Radio color="primary" data-testid={TestId.GovtIdSkipUploadOption} />}
                                        label={GovtIdCaptureOptionText.Skip}
                                    />
                                </RadioGroup>
                            </Grid>
                            <ActionModal
                                title="Confirm Skip Government ID"
                                maxWidth="sm"
                                isOpen={confirmSkipOpen}
                                handleClose={handleCloseSkip}
                                actions={dialogActions}
                            >
                                {GovtIdSkipText.SkipWarning}
                            </ActionModal>
                        </Grid>
                    </Grid>
                )}
                {captureOption === GovtIdCaptureOption.AttachImage && !showViewComponent && <AttachPhotoComponent attachLocalFile={attachLocalFile} />}
                {captureOption === GovtIdCaptureOption.TakePhoto && !showViewComponent && (
                    <Container className={classes.attachPhotoContainer} data-testid={TestId.GovtIdTakePhotoContainer}>
                        <TakePhotoComponent setImage={setImage} processingError={processingError} isProcessing={isProcessing} />
                    </Container>
                )}
                {showViewComponent && (
                    <ViewPhotoComponent
                        capturedImage={capturedImage}
                        inValidImageReason={invalidImageReason}
                        validImageText={ViewPhotoText.GovernmentId}
                        handleContinue={handleViewPhotoContinue}
                        handleRetakePhoto={handleRetakePhoto}
                    />
                )}
            </Container>
        </div>
    )
}
